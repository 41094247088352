import React from "react";
import Left from "./left.jsx";
import Content from "./content.jsx";
import bg from "../../assets/images/9722.png";

const notice = () => {
  return (
    <div className="App">
      <Left></Left>
      <Content />
      <img style={{ width: 100 + "%" }} src={bg} alt="" />
    </div>
  );
};

export default notice;
