import React from "react";
import "./style.less";
const RuleAlert = (props) => {
  let content = "";
  if (props.showAlertType === "rule") {
    content = (
      <div>
        <img src={props.bg} alt="" />
        <div
          className={props.showAlertType || ""}
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        ></div>
        <div className="closeBtn" onClick={props.close}></div>
      </div>
    );
  } else if (props.showAlertType === "record") {
    content = (
      <div className={props.showAlertType || ""}>
        <img src={props.bg} alt="" />
        <div>
          {props.text.map((v, i) => {
            return (
              <div key={i}>
                <span>{v.goods_name}</span>
                <span>{v.good_worth}</span>
                <span>{v.event_time}</span>
              </div>
            );
          })}
        </div>
        <div className="closeBtn" onClick={props.close}></div>
      </div>
    );
  }
  return (
    <div
      className="ruleAlert"
      style={props.showAlert ? { display: "block" } : { display: "none" }}
    >
      {content}
    </div>
  );
};

export default RuleAlert;
