import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import queryString from "query-string";
import { get, post } from "../../../utils/axHttp";
import libao from "./image/libao.png";
import banner from "./image/banner.jpg";
import Toast from "../../../components/Toast/index.jsx";
import RuleAlert from "../../../components/ruleAlert/index.jsx";
import ruleBg from "./image/rule.png";
import recordBg from "./image/record.png";

/**
 * 积分兑换活动
 * @author pangzh
 */
export default class IntegralShop extends React.Component {
  constructor(props) {
    document.title = "积分商城";
    super(props);
    this.state = {
      goodList: [],
      giftCode: [],
      prizes: [], // 实物列表
      isActive: true,
      balance: 0,
      activityTime: "",
      game: "",
      uname: "",
      rule: "",
      showAlert: false,
      alertRecord: [],
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    get("/activity/balanceShopList", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          giftCode: res.data.list.gift_code || [],
          prizes: res.data.list.prizes || [],
          goodList: res.data.list.gift_code || [],
          balance: res.data.balance,
          activityTime: res.data.activity_time,
          game: res.data.game,
          uname: res.data.uname,
          rule: res.data.rule,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery" },
      });
    }
  }

  /**
   * 切换列表
   * @param {*} flag
   */
  checkList(flag) {
    let isActive = flag;
    let goodList = [];
    if (flag) {
      goodList = this.state.giftCode;
    } else {
      goodList = this.state.prizes;
    }
    this.setState({
      isActive: isActive,
      goodList: goodList,
    });
  }

  myInfo() {
    this.props.history.push({
      pathname: "/user/receivinginfo",
      query: { url: window.location.href },
    });
  }

  /**
   * 兑换
   * @param {*} id
   * @param {*} index
   */
  async balanceExchange(id, index) {
    if (this.state.isActive) {
      if (this.state.giftCode[index].is_buy) {
        JmAlert.open({
          title: "提示",
          alertTip: "已经兑换过了，请勿重复兑换",
        });
        return;
      }
      let res = await post("/activity/balanceExchange", {
        goods_id: id,
        game_id: this.state.game_id,
      });
      if (res.code === 0) {
        JmAlert.open({
          title: "兑换成功",
          alertTip: res.data.gift_code,
          copy: true,
        });
        let giftCode = this.state.giftCode;
        giftCode[index].num--;
        giftCode[index].is_buy = 1;
        this.setState({
          balance: this.state.balance - giftCode[index].balance_cost,
          ...giftCode,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    } else {
      if (this.state.prizes[index].is_buy) {
        JmAlert.open({
          title: "提示",
          alertTip: "已经兑换过了，请勿重复兑换",
        });
        return;
      }
      let res = await post("/activity/balanceExchange", {
        goods_id: id,
        game_id: this.state.game_id,
      });
      if (res.code === 0) {
        JmAlert.open({
          title: "兑换成功",
          alertTip: res.message,
          copy: false,
        });
        let prizes = this.state.prizes;
        prizes[index].num--;
        prizes[index].is_buy = 1;
        this.setState({
          balance: this.state.balance - prizes[index].balance_cost,
          ...prizes,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    }
  }
  async onAlert(showAlertType) {
    console.log(showAlertType);
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      if (showAlertType === "record") {
        let res = await get("/activity/balanceExchangeRecord", {
          game_id: this.state.game_id,
        });
        console.log(res);
        if (res.code === 0) {
          this.setState({
            showAlert: true,
            showAlertType: showAlertType,
            alertRecord: res.data.list,
          });
        } else {
          JmAlert.open({
            title: "提示",
            alertTip: res.message,
          });
        }
        return;
      }
      this.setState({
        showAlert: true,
        showAlertType: showAlertType,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    return (
      <div className="integral_shop_box">
        <div>
          <div className="banner">
            <img src={banner} alt="" />
          </div>
          <div className="game_name">
            本期可兑换礼包游戏: <span>{this.state.game}</span>时间：
            <span>{this.state.activityTime}</span>
          </div>
          <div className="btn_list">
            <div onClick={this.onAlert.bind(this, "rule")}>兑换说明</div>
            <div onClick={this.onAlert.bind(this, "record")}>兑换记录</div>
            <div onClick={this.myInfo.bind(this)}>我的信息</div>
          </div>
          <div className="my_mili">
            我的米粒：<span>{this.state.balance || "【请登录】"}</span>
          </div>
        </div>
        <div className="checkBtn">
          <div
            onClick={this.checkList.bind(this, true)}
            className={this.state.isActive ? "active" : "btn1"}
          >
            礼包码
          </div>
          <div
            onClick={this.checkList.bind(this, false)}
            className={this.state.isActive ? "btn1" : "active"}
          >
            实物奖品
          </div>
        </div>
        <div className="list">
          <div className="libao_code">
            {this.state.goodList.map((v, i) => {
              return (
                <div key={v.id}>
                  <div className="libao_code_left">
                    <img src={libao} alt="" />
                    <div>
                      <h3>{v.goods_name}</h3>
                      <div>所需米粒：{v.balance_cost}</div>
                      <div>剩余次数：{v.num}</div>
                    </div>
                  </div>
                  <div
                    className={`btn ${v.is_buy && "ydh"}`}
                    onClick={this.balanceExchange.bind(this, v.id, i)}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <RuleAlert
          bg={this.state.showAlertType === "rule" ? ruleBg : recordBg}
          showAlertType={this.state.showAlertType}
          text={
            this.state.showAlertType === "rule"
              ? this.state.rule
              : this.state.alertRecord
          }
          showAlert={this.state.showAlert}
          close={this.onAlert.bind(this)}
        />
      </div>
    );
  }
}
