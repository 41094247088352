import React from "react";
import Header from "../../components/Header/index.jsx";
class Test extends React.Component {
  componentDidMount() {
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module === "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          console.log("location", loc);
        }
      },
      false
    );
  }
  render() {
    return (
      <div>
        <Header theme="orange"></Header>
        <h1>我是测试页</h1>
        <div>123213123</div>
        {/* <iframe
          title="地图"
          id="mapPage"
          width="100%"
          height="500"
          src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=OBJBZ-776K5-G62I2-QMQST-CQZG7-BVBC5&referer=myapp"
        ></iframe> */}
      </div>
    );
  }
}

export default Test;
