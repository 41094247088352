import React, { useState } from "react";

// import red_bgs from "./image/red_bgs.png";
import red_pointer from "./image/red_pointer2.png";
import red_turntable from "./image/red_turntable2.png";
import "./style/index.less";

const giftArr = [
  { giftName: "1200" },
  { giftName: "1400" },
  { giftName: "3000" },
  { giftName: "4000" },
  { giftName: "1100" },
];

const zp = {
  width: 9,
  height: 9,
};

const redTurntable = {
  width: zp.width - 1 + "rem",
  height: zp.height - 1 + "rem",
  marginLeft: -(zp.width - 1) / 2 + "rem",
  marginTop: -(zp.height - 1) / 2 + "rem",
};

const rnd = (n, m) => {
  let num = Math.floor(Math.floor(Math.random() * (m - n + 1) + n));
  return num;
};

/**
 * 主要组件块
 * @param {*} props
 */
const ZP = (props) => {
  const [reagePlay, setReagePlay] = useState({});
  const [isStart, setIsStart] = useState(true);
  const start = () => {
    if (!isStart) {
      return;
    }
    setIsStart(false);
    props.action((data) => {
      // console.log("data===>", data);

      // console.log("开始旋转");
      // let reage = rnd(1, 5);
      let reage = data.index || rnd(1, 5);
      // console.log(giftArr);
      // console.log(reage);
      // console.log(reagePlay);

      let rotate = 360 * 9 - ((reage - 1) * 360) / giftArr.length;
      // console.log(((reage - 1) * 360) / giftArr.length);
      // console.log(giftArr[reage - 1]);
      // setReagePlay({ animation: "rotate 3s linear infinite" });
      setReagePlay({
        transform: "rotate(" + rotate + "deg)",
        transition: "8s",
      });
      // 相应的角度 + 满圈 只是在原角度多转了几圈 360 * 6
      // let rotate = 2160 * (this.rotNum + 1) + angle;
      // this.oTurntable.style.webkitTransform = 'rotate(' + rotate + 'deg)';
      // clearTimeout(this.timer);
      // // 设置5秒后停止旋转,处理接口返回的数据
      let timer = setTimeout(() => {
        setIsStart(true);
        setReagePlay({});
        window.clearTimeout(timer);
        stop();
      }, 8000);
    });
  };

  const stop = () => {
    props.stop();
  };

  return (
    <div>
      <div
        className="red_box"
        style={{ width: zp.width + "rem", height: zp.height + "rem" }}
      >
        {/* <img className="red_bgs" src={red_bgs} alt="" /> */}
        <div style={redTurntable} className="red_turntable">
          <img style={reagePlay} src={red_turntable} alt="" />
        </div>
        <img
          style={{ width: zp.width - 6 + "rem" }}
          className="red_pointer"
          onClick={start}
          src={red_pointer}
          alt=""
        />
        <div></div>
      </div>
    </div>
  );
};

export default ZP;
