import React from "react";
import rightIcon from "./image/right_icon.png";
import headerBackBtn from "./image/header_back_btn.png";
import "./style.less";

const back = () => {
  console.log("后退");
};

const header = (props) => {
  const themes = ["classic", "orange"];
  // console.log(props);
  let content;
  switch (props.theme) {
    case themes[1]:
      content = (
        <div className="orange">
          <div className="back_btn" onClick={back}>
            <img src={headerBackBtn} alt="" />
          </div>
          <div>{props.title}</div>
        </div>
      );
      break;
    case themes[0]:
      content = (
        <div className="classic">
          <div className="back_btn right-icon" onClick={back}>
            <img src={rightIcon} alt="" />
          </div>
          <div>{props.title}</div>
        </div>
      );
      break;
    default:
      content = (
        <div className="orange">
          <div>{props.title}</div>
        </div>
      );
  }
  // console.log(content);
  return <div className="header-body">{content}</div>;
};

export default header;
