import React from "react";
const IeAlert = (props) => {
  let content = "";
  if (props.showAlertType === "rule") {
    content = (
      <div
        className={props.showAlertType || ""}
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      ></div>
    );
  } else if (props.showAlertType === "record") {
    content = (
      <div className={props.showAlertType || ""}>
        <div>
          {props.text.map((v) => {
            return (
              <div>
                <span>{v.event_time}</span>
                <span>{v.goods_name}</span>
                <span>{v.integral_cost}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div
      className="ie_alert"
      style={props.showAlert ? { display: "block" } : { display: "none" }}
      onClick={props.close}
    >
      {content}
    </div>
  );
};

export default IeAlert;
