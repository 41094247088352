import React, { Fragment } from "react";
import "./index.less";
import money from "./images/money.png";
import rule from "./images/rule.png";
import redBagPng from "./images/red-bag.png";
import { get, post } from "../../../utils/axHttp";
// import Toast from "../../../components/Toast/index.jsx";
import JmAlert from "../../../components/JmAlert/index.jsx";
import NoTimeContent from "./components/NoTimeContent";
import ScrollList from "./components/ScrollList";
/**
 * 市场红包页
 */
export default class RedBag extends React.Component {
  constructor(props) {
    super(props);
    document.title = "注册秒领500";
    this.state = {
      page: 1,
      uname: "",
      balance: 0,
      bonus: 0,
      bag_list: [],
      deadline: 0,
      rule: "",
      showChangeRole: false,
      serverList: [],
      serverIndex: [0, 0],
      role_info: { bind_time: 0, role_name: "" },
    };
    this.handleServerChange.bind(this);
    this.bindRole.bind(this);
  }
  componentDidMount() {
    this.redPacketTaskList();
  }

  // 请求红包列表
  redPacketTaskList() {
    get("/activity/redPacketTaskList", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        if (!res.data.is_bind_role) {
          this.getUserRole();
        }
        this.setState({
          uname: res.data.uname,
          balance: res.data.balance,
          bonus: res.data.bonus,
          deadline: res.data.deadline,
          rule: res.data.rule,
          bag_list: res.data.task_list.level,
          role_info: res.data.role_info,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
          closeAlert: () => {
            console.log("关闭弹窗");
          },
        });
      }
    });
  }

  // 获取角色信息
  getUserRole() {
    get("/activity/userRoleInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          showChangeRole: true,
          serverList: res.data,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: `暂无角色，无法领取奖励，请尽快创建角色。`,
          closeAlert: () => {
            console.log("关闭弹窗");
          },
        });
      }
    });
  }

  // 监听选择框
  handleServerChange = (event) => {
    console.log(event.target.name);
    let serverIndex = this.state.serverIndex;
    if (event.target.name === "server") {
      serverIndex[0] = event.target.value;
    } else {
      serverIndex[1] = event.target.value;
    }
    this.setState({ serverIndex: serverIndex });
    // this.setState({ value: event.target.value });
  };

  bindRole = () => {
    const serverInfo = this.state.serverList[this.state.serverIndex[0]];
    console.log(serverInfo);
    const roleInfo = serverInfo.role_data[this.state.serverIndex[1]];
    post("/activity/userRoleBind", {
      server_no: serverInfo.server_no,
      server_name: serverInfo.server_name,
      role_id: roleInfo.role_id,
      role_name: roleInfo.role_name,
    }).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({ showChangeRole: false });
        this.redPacketTaskList();
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  };

  completeRedPacketTask(task_id) {
    post("/activity/completeRedPacketTask", {
      task_id: task_id,
    }).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.redPacketTaskList();
      }
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    });
  }
  goPage(page) {
    // this.props.history.push({
    //   pathname: "/activity/orther?page=" + page,
    //   query: { page: page },
    // });
    this.props.history.push("/activity/orther?page=" + page);
  }
  render() {
    const btns = (status, task_id) => {
      //   return <div>{status}领取</div>;
      // eslint-disable-next-line no-unreachable
      switch (status) {
        case 0:
          return <div>未达标</div>;
        case 1:
          return (
            <div
              className="lq"
              onClick={this.completeRedPacketTask.bind(this, task_id)}
            >
              领取
            </div>
          );
        case 2:
          return <div className="complate"></div>;
        case 3:
          return <div className="last"></div>;
        default:
          return <div></div>;
      }
    };

    // 单页切换
    // let pageContent = "";
    switch (this.state.page) {
      case 1:
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <div className="ac_red_bag">
          <div className="head">
            <div>用户{this.state.uname}</div>
            <div>
              <div>{this.state.role_info.role_name}</div>
              <div>
                （{this.state.role_info.role_level}级/创角
                {this.state.role_info.bind_time}）
              </div>
            </div>
            <div>
              <div>
                <img src={money} alt="" />
                <span>{this.state.balance}元</span>
              </div>
              <div onClick={this.goPage.bind(this, 1)}>明细</div>
              <div onClick={this.goPage.bind(this, 2)}>提现</div>
            </div>
          </div>

          <div className="msg">
              <ScrollList />
          </div>
          <div className="cash_box">
            <div className="cash_box_head">
              <div>
                <span className="money">{this.state.bonus}元</span>
                <span>可领奖金</span>
              </div>
              <div>
                <img src={rule} alt="" onClick={this.goPage.bind(this, 3)} />
              </div>
            </div>
            <div className="time">
              剩余领取时间： <NoTimeContent time={this.state.deadline} />
            </div>
            <div className="level_bag">等级红包</div>
            <div>
              {this.state.bag_list?.map((v, i) => {
                return (
                  <div className="level_item" key={i}>
                    <div>
                      <img src={redBagPng} alt="" />
                      <div>
                        <div>{v.point}元</div>
                        <div>{v.task_desc}</div>
                      </div>
                    </div>
                    {btns(v.status, v.task_id)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="bagChangeRole"
          style={{ display: this.state.showChangeRole ? "block" : "none" }}
        >
          <div>
            <select name="server" id="" onChange={this.handleServerChange}>
              {this.state.serverList.map((v, i) => {
                return (
                  <option key={v.server_no} value={i}>
                    {v.server_name}
                  </option>
                );
              })}
            </select>
            <select name="role" id="" onChange={this.handleServerChange}>
              {this.state.serverList[this.state.serverIndex[0]]?.role_data.map(
                (v, i) => {
                  return (
                    <option key={v.role_id} value={i}>
                      {v.role_name}
                    </option>
                  );
                }
              )}
            </select>
            <button onClick={this.bindRole}>确定</button>
          </div>
        </div>
      </Fragment>
    );
  }
}
