import React from "react";
import "./style.less";
import HgBtn from "./images/hg_btn.png";
import XzBtn from "./images/xz_btn.png";
import PageQyzBtn from "./images/page_qyz_btn.png";
import PageQyjBtn from "./images/page_qyj_btn.png";
// import DownloadBtn from "./images/download.png";

import page2_bg1 from "./images/page2_bg1.jpg";
import page2_bg2 from "./images/page2_bg2.jpg";
import page2_header from "./images/page2_header.jpg";
import page2_top from "./images/page2_top.png";
import page3_qyz from "./images/page3_qyz_bg.jpg";
import page3_qyj from "./images/page3_qyj2_bg_2.jpg";
import qyj_hg_lb from "./images/qyj2/qyj_hg_lb.png";
import qyz_dl_bar from "./images/qyz_dl_bar.png";
import qyj2_dl_bar from "./images/qyj2_dl_bar.png";

import qyz_2k from "./images/qyz/2k_04.png";
import qyz_5k from "./images/qyz/5k_04.png";
import qyz_1w from "./images/qyz/1w_04.png";
import qyz_2w from "./images/qyz/2w_04.png";
import qyj2_hg_1000_02 from "./images/qyj2/qyj2_hg_1000_02.png";
import qyj2_hg_3000_02 from "./images/qyj2/qyj2_hg_3000_02.png";
import qyj2_hg_5000_03 from "./images/qyj2/qyj2_hg_5000_03.png";
import qyj2_hg_10000_04 from "./images/qyj2/qyj2_hg_10000_04.png";
import qyj2_hg_20000_05 from "./images/qyj2/qyj2_hg_20000_05.png";
import queryString from "query-string";
// import HttpUtils from "../../../utils/HttpUtils";
import ReactSwiper from "reactjs-swiper";

// import main from "./images/main.jpg";
import main2 from "./images/main2.jpg";

export default class Sign extends React.Component {
  constructor(props) {
    document.title = "老玩家回归"; // 修改标题
    super(props);
    this.state = {
      page: 0,
      type: 1, // 0青云传 ， 1 青云决
      isiOS: false,
    };
  }
  componentDidMount() {
    let u = navigator.userAgent;
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    this.setState({ isiOS: isiOS });
    console.log(isiOS);
    if (window.history && window.history.pushState) {
      window.onpopstate = (e) => {
        // console.log(e.currentTarget);
        let params = queryString.parse(
          e.currentTarget.location.hash.split("?")[1]
        );
        if (params.num) {
          // console.log(params);
          this.setState({ page: Number(params.num) });
        } else {
          this.setState({ page: 0 });
        }
        // //后退按钮触发事件
        // console.log("后退");
        if (params.num === "2") {
          document.title = "老服回归送豪礼";
        } else if (params.num === "3") {
          document.title = "新服好礼送不停";
        } else {
          document.title = "老玩家回归";
        }
      };
    }
  }
  typeChange(type) {
    this.setState({ type: type, page: 1 });
    if (window.location.href.indexOf("?") < 0) {
      window.location.href = window.location.href + "?num=" + 1;
    }
  }
  goPage(num) {
    // const href = window.location.origin + window.location.hash;
    window.location.href =
      window.location.href.substring(0, window.location.href.length - 1) + num;
    // console.log(href);
    // window.history.pushState({ ll: 1 }, 0, href);
    this.setState({ page: num });
  }
  download() {
    console.log("下载");
    if (this.state.type === 0) {
      window.location.href = "http://p.ml5i.com/html/5/5166.html";
    } else {
      window.location.href = "https://p.ml5i.com/html/5/5164.html";
    }
  }
  render() {
    let content = "";
    switch (this.state.page) {
      case 1:
        content = (
          <div className="page1">
            <img className="bg" src={main2} alt="" />
            <div className="dl">
              <img
                src={this.state.type === 1 ? qyj2_dl_bar : qyz_dl_bar}
                alt=""
              />
              <div
                onClick={() => {
                  console.log("下载");
                  if (this.state.type === 0) {
                    window.location.href = "http://p.ml5i.com/html/5/5166.html";
                  } else {
                    window.location.href =
                      "https://p.ml5i.com/html/5/5164.html";
                  }
                }}
              ></div>
            </div>
            <img
              onClick={this.goPage.bind(this, 2)}
              className="btn hg"
              src={HgBtn}
              alt=""
            />
            <img
              onClick={this.goPage.bind(this, 3)}
              className="btn xz"
              src={XzBtn}
              alt=""
            />
            <div></div>
            {/* <img
              onClick={() => {
                console.log("下载");
                if (this.state.type === 0) {
                  window.location.href = "http://p.ml5i.com/html/5/5166.html";
                } else {
                  window.location.href = "https://p.6tc6.com/html/5/5164.html";
                }
              }}
              className="btn dl"
              src={DownloadBtn}
              alt=""
            /> */}
          </div>
        );
        break;
      case 2:
        const swiperOptions = {
          preloadImages: true,
          autoplay: false,
          autoplayDisableOnInteraction: false,
        };

        if (this.state.type === 0) {
          const items = [
            {
              image: qyz_2k,
              title: "图片1",
            },
            {
              image: qyz_5k,
            },
            {
              image: qyz_1w,
              title: "图片3",
              // link: "#",
            },
            {
              image: qyz_2w,
              title: "图片4",
            },
          ];
          content = (
            <div className="page2">
              <div className="dl">
                <img
                  src={this.state.type === 1 ? qyj2_dl_bar : qyz_dl_bar}
                  alt=""
                />
                <div onClick={this.download.bind(this)}></div>
              </div>
              <img src={page2_bg1} alt="" />
              <img src={page2_bg2} alt="" />
              <div className="page2_bg3">
                <img className="page2_top" src={page2_top} alt="" />
              </div>
              <div className="page2_bg4">
                <ReactSwiper
                  swiperOptions={swiperOptions}
                  showPagination
                  items={items}
                  className="swiper-example"
                />
              </div>
            </div>
          );
        } else {
          const items = [
            {
              image: qyj2_hg_1000_02,
            },
            {
              image: qyj2_hg_3000_02,
            },
            {
              image: qyj2_hg_5000_03,
            },
            {
              image: qyj2_hg_10000_04,
            },
            {
              image: qyj2_hg_20000_05,
            },
          ];
          content = (
            <div className="page2">
              <div className="dl">
                <img
                  src={this.state.type === 1 ? qyj2_dl_bar : qyz_dl_bar}
                  alt=""
                />
                <div onClick={this.download.bind(this)}></div>
              </div>
              {/* <img
                onClick={this.download}
                className="dl"
                src={DownloadBtn}
                alt=""
              /> */}
              <img src={page2_header} alt="" />
              {/* <img src={page2_bg2} alt="" /> */}
              <div className="page2_bg3_03">
                <img className="page2_top" src={qyj_hg_lb} alt="" />
              </div>
              <div className="page2_bg4_04">
                <ReactSwiper
                  swiperOptions={swiperOptions}
                  showPagination
                  items={items}
                  className="swiper-example"
                />
              </div>
            </div>
          );
        }
        break;
      case 3:
        content = (
          <div className="page3">
            <div className="dl">
              <img
                src={this.state.type === 1 ? qyj2_dl_bar : qyz_dl_bar}
                alt=""
              />
              <div onClick={this.download.bind(this)}></div>
            </div>
            <img src={this.state.type === 1 ? page3_qyj : page3_qyz} alt="" />
            {/* <img src={page2_bg2} alt="" /> */}
          </div>
        );
        break;
      default:
        content = (
          <div className="page">
            <img
              onClick={this.typeChange.bind(this, 0)}
              className="qyz"
              src={PageQyzBtn}
              alt=""
            />
            <img
              onClick={this.typeChange.bind(this, 1)}
              className="qyj"
              src={PageQyjBtn}
              alt=""
            />
          </div>
        );
    }
    return <div className="ac_regression">{content}</div>;
  }
}
