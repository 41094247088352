import axios from "axios"; // 引入axios
// import QS from 'qs';

// axios.defaults.baseURL = 'http://127.0.0.1:3000';
// axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : "/api"; //测试
const baseUrl = process.env.NODE_ENV === "production" ? "" : "/api"; //测试
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {}
);

// export default axios;

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + url, {
        params: params,
      })
      .then((response) => {
        // landing(url, params, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(baseUrl + url, data).then(
      (response) => {
        //关闭进度条
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * put请求
 * @param {*} url
 * @param {*} data
 */
export function put(url, data) {
  return new Promise((resolve, reject) => {
    axios.put(baseUrl + url, data).then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function setCookie(name, value) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}
