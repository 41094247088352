/** @format */

import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import btn1 from "./image/btn1.png";
import btn2 from "./image/btn2.png";
/**
 * 预约活动2
 * @author pangzh
 */
export default class Booking2 extends React.Component {
  constructor(props) {
    document.title = "预约活动2";
    super(props);
    this.state = {
      miliRebateList: [],
      uname: "",
      showAlert: false,
      activity_time: "",
      get_rebate_time: "",
      current_date: "appointment_date",
      is_appointment: 0,
      reward: 0,
      rule: "",
    };
  }
  componentDidMount() {
    // 1:已领取 2：带岭区 0:没打成
    get("/activity/miliAppointmentInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          miliRebateList: res.data.list,
          uname: res.data.uname,
          activity_time: res.data.activity_time,
          get_rebate_time: res.data.get_rebate_time,
          current_date: res.data.current_date,
          is_appointment: res.data.is_appointment,
          reward: res.data.reward,
          rule: res.data.rule,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/booking2", url: window.location.href },
      });
    }
  }
  async join(index) {
    let res = await post("/activity/makeMiliAppointment", {});
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      this.setState({ is_appointment: 1 });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    let content = "";
    if (this.state.is_appointment === 0) {
      content = (
        <div className="text">
          {/* 您一共可获得{this.state.reward}米粒 */}
          {/* <br /> */}
          <img onClick={this.join.bind(this)} src={btn1} alt="" />
        </div>
      );
    } else {
      content = (
        <div className="text">
          <img src={btn2} alt="" />
        </div>
      );
    }

    return (
      <div className="AcBooking2">
        {/* <div className="time1">活动时间：{this.state.activity_time}</div> */}
        {/* <div className="time2">领取时间：{this.state.get_rebate_time}</div> */}
        <div className="rebate_list">
          <div className="btn_box">{content}</div>
          <div
            className="rule"
            dangerouslySetInnerHTML={{
              __html: this.state.rule,
            }}
          ></div>
        </div>
      </div>
    );
  }
}
