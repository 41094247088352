import one from "./image/hb_bg.jpg";
import two from "./image/hb_bg2.png";
import colse from "./image/colse.png";
import React from "react";
import "./style.less";
import queryString from "query-string";
import HttpUtils from "../../../utils/HttpUtils";
export default class Window extends React.Component {
  constructor(props) {
    document.title = "广告"; // 修改标题
    super(props);
    this.state = { date: new Date(), giftInfo: [], popup: 2 };
    console.log("构造函数");
  }

  componentWillMount() {
    console.log("载入ui");
  }
  closeWebView() {
    console.log("关闭弹窗");
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (
      typeof parent.jimiJS !== "undefined" &&
      parent.jimiJS.closeWebView
    ) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }
  goLink() {
    let params = queryString.parse(this.props.location.search);
    let skin = params.skin;
    this.putRedPackageLog(2);
    // 根据域名参数跳转到指定地方
    if (skin == 1) {
      window.location.href = "/sdk_new/dist/#/home?fromGame=1&version=1";
    } else {
      window.location.href = "/community/dist/task.html";
    }
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState({ popup: params.popup });
    this.putRedPackageLog(1);
  }
  /**
   * 上报操作
   * @param {} tag 展示是 1 点击 2
   */
  putRedPackageLog(tag) {
    HttpUtils.post("/webConfig/redPackageLog", { tag: tag });
  }
  render() {
    return (
      <div className="a-win-hb">
        <img className="colse" src={colse} onClick={this.closeWebView} alt="" />
        <div className="bg">
          <img
            onClick={this.goLink.bind(this)}
            src={this.state.popup === "1" ? one : two}
            alt=""
          />
        </div>
      </div>
    );
  }
}
