import React from "react";
import Header from "../../components/Header/index.jsx";
import CloseView from "../../components/CloseView/index.jsx";
import "./style.less";
import JmAlert from "../../components/JmAlert/index.jsx";
import Toast from "../../components/Toast/index.jsx";
const Home = () => (
  <div>
    <Header theme="orange"></Header>
    <CloseView />
    <h1 onClick={openAlert}>我是弹窗</h1>
    <h2 onClick={openToast}>我是Toast</h2>
  </div>
);

const openAlert = () => {
  JmAlert.open({
    alertTip: "dsafdfasdfasfdf",
    closeAlert: function () {
      console.log("关闭了...");
    },
  });
};

const openToast = () => {
  console.log("loading test");
  Toast.loading(true);
};

export default Home;
