import axios from "axios";
axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : "/api"; //测试

function getQueryVariable(variable) {
  //   console.log(window.location);
  var query = window.location.hash.split("?")[1];
  if (!query) {
    return false;
  }
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

console.log(getQueryVariable("game_id"));

var Axios = axios.create();
// 请求拦截
Axios.interceptors.request.use(
  (config) => {
    // token认证写在这里
    let access_token = getQueryVariable("access_token");
    let game_id = getQueryVariable("game_id");
    if (game_id) {
      config.params.game_id = game_id;
    }
    if (access_token) {
      config.params.access_token = access_token;
    }
    // console.log(config);
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);
// 响应拦截
Axios.interceptors.response.use(
  (config) => {
    return config.data;
  },
  (err) => {
    Promise.reject(err);
  }
);
// get封装
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url,
      params,
      method: "get",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// post封装
export function post(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url,
      method: "post",
      params,
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// delete封装
export function del(url, params = {}, data = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url,
      method: "delete",
      params,
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
//   Blob封装,resopnseType:Blob一般是用于文件下载
export function getBlob(url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url,
      method: "get",
      params,
      responseType: "blob",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUrlToken(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

export default {
  get,
  post,
  del,
  getBlob,
};
