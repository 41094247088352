import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Home from "../pages/home";
import Test from "../pages/TestView/index.jsx";
import Login from "../pages/login/index.jsx";
import Sign from "../pages/sign/index.jsx";
import Download from "../pages/activity/download/download.jsx";
import GiftBag1 from "../pages/activity/giftbag1/index.jsx";
import Window1 from "../pages/activity/window/index.jsx";
import Window2 from "../pages/activity/window/index2.jsx";
import Window3 from "../pages/activity/window/index3.jsx";
import Win from "../pages/activity/window/win.jsx";
import DyActivity from "../pages/activity/dyActivity/win.jsx";
import Notice from "../pages/notice/index.jsx";
import CustomerService from "../pages/CustomerService/index.jsx";
import Questionnaire from "../pages/activity/questionnaire/index.jsx";
import Lottery from "../pages/activity/lottery1/index.jsx";
import Ranking from "../pages/activity/ranking/index.jsx";
// 玩家回归页
import Regression from "../pages/activity/regression/index.jsx";
// 活动登录页
import acSign from "../pages/activity/sign/index.jsx";
// 砸金蛋
import SmashEgg from "../pages/activity/SmashEgg/index.jsx";
import IntegralShop from "../pages/activity/integralShop/index.jsx";
import IntegralExchange from "../pages/activity/integralExchange/ie.jsx";
import ReceivingInfo from "../pages/user/ReceivingInfo/index.jsx";
import MiliReturn from "../pages/activity/miliReturn/index.jsx";
import MiliMakeVow from "../pages/activity/miliMakeVow/index2.jsx";
import CZYY from "../pages/activity/czyy/index.jsx";
import Divination from "../pages/activity/divination/index.jsx";
import Fireworks from "../pages/activity/fireworks/index.jsx";
import Booking2 from "../pages/activity/booking/index.jsx";
import MiliWeeksCard from "../pages/activity/miliWeeksCard/index.jsx";
import RedBag from "../pages/activity/RedBag/index.jsx";
import MiliGift from "../pages/activity/miligift/index.jsx";
import ortherRedBag from "../pages/activity/RedBag/orther.jsx";
import GameCenter from "../pages/gameCenter/index.jsx";
import Wait from "../pages/wait/index.jsx";
import Wait2 from "../pages/wait/index2.jsx";
import Wait3 from "../pages/wait/index3.jsx";
import Wait4 from "../pages/wait/index4.jsx";
import DoubleRebate from "../pages/activity/doubleRebate/index.jsx";


const Main = () => (
  <main>
    <HashRouter>
      <Switch>
        <Route exact path="/home" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign" component={Sign} />
        <Route exact path="/test" component={Test} />
        <Route exact path="/activity/download" component={Download} />
        <Route exact path="/activity/giftbag1" component={GiftBag1} />
        <Route exact path="/activity/window1" component={Window1} />
        <Route exact path="/activity/window2" component={Window2} />
        <Route exact path="/activity/hb" component={Window3} />
        <Route exact path="/activity/win" component={Win} />
        <Route exact path="/activity/dy" component={DyActivity} />
        <Route exact path="/activity/questionnaire" component={Questionnaire} />
        <Route exact path="/activity/lottery" component={Lottery} />
        <Route exact path="/activity/ranking" component={Ranking} />
        <Route exact path="/activity/sign" component={acSign} />
        <Route exact path="/activity/regression" component={Regression} />
        <Route exact path="/activity/smashegg" component={SmashEgg} />
        <Route exact path="/activity/riceshop" component={IntegralShop} />
        <Route exact path="/activity/ie" component={IntegralExchange} />
        <Route exact path="/activity/milireturn" component={MiliReturn} />
        <Route exact path="/activity/milivow" component={MiliMakeVow} />
        <Route exact path="/activity/miligift" component={MiliGift} />
        <Route exact path="/activity/czyy" component={CZYY} />
        <Route exact path="/activity/divination" component={Divination} />
        <Route exact path="/activity/fireworks" component={Fireworks} />
        <Route exact path="/activity/booking2" component={Booking2} />
        <Route exact path="/activity/miliweekscard" component={MiliWeeksCard} />
        <Route exact path="/activity/redBag" component={RedBag} />
        <Route exact path="/activity/orther" component={ortherRedBag} />
        <Route exact path="/activity/doubleRebate" component={DoubleRebate} />
        <Route exact path="/user/receivinginfo" component={ReceivingInfo} />
        <Route exact path="/notice" component={Notice} />
        <Route exact path="/service" component={CustomerService} />
        <Route exact path="/gamecenter" component={GameCenter} />
        <Route exact path="/wait" component={Wait} />
        <Route exact path="/wait2" component={Wait2} />
        <Route exact path="/wait3" component={Wait3} />
        <Route exact path="/wait4" component={Wait4} />
        {/* <Route path='/player/:id' component={Player}/> */}
      </Switch>
    </HashRouter>
  </main>
);
export default Main;
