import React, { Component, Fragment } from "react";
import { is, fromJS } from "immutable";
import ReactDOM from "react-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./style.less";
import { CopyToClipboard } from "react-copy-to-clipboard";

class JmAlert extends Component {
  static NodeId = "jm-alert";

  state = {
    alertStatus: true,
    title: "提示",
    alertTip: "",
    closeAlert: function () {},
  };
  // css动画组件设置为目标组件
  FirstChild = (props) => {
    const childrenArray = React.Children.toArray(props.children);
    return childrenArray[0] || null;
  };
  // 关闭弹框
  static confirm = (closeAlert = "") => {
    ReactDOM.unmountComponentAtNode(document.getElementById(this.NodeId));
    const modal = document.getElementById(this.NodeId);
    document.body.removeChild(modal);
    if (closeAlert) {
      closeAlert();
    }
  };
  static open = (options) => {
    options = { title: "提示", ...options };
    const div = document.createElement("div");
    div.id = this.NodeId;
    document.body.appendChild(div);
    ReactDOM.render(
      <ReactCSSTransitionGroup
        component={this.FirstChild}
        transitionName="hide"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        <div className="jm-alert-page-con">
          <div className="jm-alert-page-context">
            <div className="jm-alert-page-title">{options.title}</div>
            {options.copy ? (
              <Fragment>
                <div className="jm-alert-page-content-detail">
                  <div>{options.alertTip}</div>
                </div>
                <CopyToClipboard
                  text={options.alertTip} //点击复制时的内容,可自行设置或传入
                >
                  <div key="copy" className="comfirm" onClick={this.confirm}>
                    复制
                  </div>
                </CopyToClipboard>
              </Fragment>
            ) : (
              <Fragment>
                <div className="jm-alert-page-content-detail">
                  <div>{options.alertTip}</div>
                </div>
                <div
                  className="comfirm"
                  onClick={this.confirm.bind(this, options.closeAlert)}
                >
                  确认
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </ReactCSSTransitionGroup>,
      div
    );
  };
  close() {
    this.state.closeAlert();
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !is(fromJS(this.props), fromJS(nextProps)) ||
      !is(fromJS(this.state), fromJS(nextState))
    );
  }
}

export default JmAlert;
