import React from "react";
import "./style.less";
import title1 from "./images/title1.png";
import qdBtn from "./images/qd_btn.png";
import myjlBtn from "./images/myjl.png";
import ruleBtn from "./images/rule_btn.png";
import ylq from "./images/ylq.png";
import queryString from "query-string";
import HttpUtils from "../../../utils/HttpUtils";

export default class Sign extends React.Component {
  constructor(props) {
    // document.title = "签到"; // 修改标题
    super(props);
    this.state = {
      staticDay: ["一", "四", "七", "十二"],
      box: ["普通宝箱", "精致宝箱", "神秘宝箱", "至尊宝箱"],
      alertStatus: false,
      alertNum: 0,
      alertMsg: "",
      statusList: [],
      lbCode: "",
      day: 0, // 签到日期
      rewardLst: [], // 领取记录
      ruleList: [],
    };
    // this.onSignGift = this.onSignGift.bind(this);
  }
  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params.type === "1") {
      document.title = "青云决2-签到";
    } else {
      document.title = "青云传-签到";
    }
    HttpUtils.get("/Webconfig/getSignInfo?type=" + params.type)
      .then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.setState({
            statusList: res.data.status_list,
            day: res.data.len,
            type: params.type,
            uname: res.data.uname,
            ruleList: res.data.rule,
          });
        } else if (res.code === 44309) {
          setTimeout(() => {
            this.props.history.push({
              pathname: "/login",
              query: { backUrl: "/activity/questionnaire" },
            });
          }, 1000);
          this.setState({
            alertNum: 1,
            alertStatus: true,
            alertMsg: res.message,
          });
        }
      })
      .then(() => {});
    HttpUtils.post("/Webconfig/signClickLog", { type: params.type, tag: 1 });
  }
  /**
   * 签到接口
   */
  onSign() {
    HttpUtils.post("/webconfig/userSign", { type: this.state.type })
      .then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.setState({
            day: res.data.len,
            alertNum: 1,
            alertStatus: true,
            alertMsg: res.message,
            statusList: res.data.status_list,
          });
        } else {
          this.setState({
            alertNum: 1,
            alertStatus: true,
            alertMsg: res.message,
          });
        }
      })
      .then(() => {});
  }

  /**
   * 當前領取禮包的下標
   * @param {*} num
   */
  onSignGift(num) {
    HttpUtils.get("/webconfig/signGift?type=" + this.state.type + "&num=" + num)
      .then((res) => {
        console.log(res);
        if (res.code === 0) {
          let statusList = this.state.statusList;
          statusList[num] = 1;
          this.setState({
            alertNum: 2,
            alertStatus: true,
            lbCode: res.data.gift_code,
            statusList: statusList,
          });
        } else {
          this.setState({
            alertNum: 1,
            alertStatus: true,
            alertMsg: res.message,
          });
        }
      })
      .then(() => {});
  }
  /**
   * 领取记录
   */
  myReward() {
    HttpUtils.get(
      "/webconfig/getSignGift?type=" +
        this.state.type +
        "&uname=" +
        this.state.uname
    ).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          rewardLst: res.data,
          alertNum: 3,
          alertStatus: true,
        });
      }
    });
  }
  onClose() {
    if (this.state.alertStatus) {
      this.setState({ alertStatus: false, alertNum: 0 });
    } else {
      this.setState({ alertStatus: true, alertNum: 0 });
    }
  }
  render() {
    let alertContent;
    switch (this.state.alertNum) {
      case 1:
        alertContent = <div className="alertMsg">{this.state.alertMsg}</div>;
        break;
      case 2:
        alertContent = (
          <div className="lb_box">
            <div>领取成功，签到{this.state.day}日礼包</div>
            <div>【{this.state.lbCode}】</div>
            <div>(长按复制礼包码)</div>
          </div>
        );
        break;
      case 3:
        alertContent = (
          <div className="reward_box">
            <div className="title">我的奖励</div>
            <div>
              {this.state.rewardLst.map((v) => {
                return (
                  <div key={v.gift_code}>
                    <div>{v.name}</div>
                    <div>【{v.gift_code}】</div>
                    <div>{v.date}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
        break;
      default:
        alertContent = "";
    }
    return (
      <div className="ac_sign">
        <img className="title1" src={title1} alt="" />
        <div className="box1">
          <div className="title">累计签到数</div>
          <div className="rl">
            <span>{this.state.day}</span>
            <span>天</span>
          </div>
        </div>
        <div className="reward">
          <img
            onClick={this.onSign.bind(this)}
            className="qd_btn"
            src={qdBtn}
            alt=""
          />
          <img
            onClick={this.myReward.bind(this)}
            className="qd_btn"
            src={myjlBtn}
            alt=""
          />
          {/* <div onClick={this.myReward.bind(this)} className="reward">
            我的奖励
          </div> */}
        </div>
        <div className="box2">
          <div>
            {this.state.statusList.map((v, i) => {
              if (v === 0) {
                return (
                  <div key={i}>
                    <div className="number">
                      签到{this.state.staticDay[i]}日
                    </div>
                    <div className="title">{this.state.box[i]}*1</div>
                    <div className="nbtn">签到领取</div>
                  </div>
                );
              } else if (v === 2) {
                return (
                  <div key={i}>
                    <div className="number">
                      签到{this.state.staticDay[i]}日
                    </div>
                    <div className="title">{this.state.box[i]}*1</div>
                    <div
                      onClick={this.onSignGift.bind(this, i)}
                      className="btn"
                    >
                      点击领取
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={i}>
                    <div className="number">
                      签到{this.state.staticDay[i]}日
                    </div>
                    <div className="title">{this.state.box[i]}*1</div>
                    <div className="nbtn">已领取</div>
                    <img className="ylq" src={ylq} alt="" />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <img className="rule_title" src={ruleBtn} alt="" />
        <div className="rule_box">
          <ul>
            {this.state.ruleList.map((v, i) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
        <div
          className="alert_box"
          style={
            this.state.alertStatus ? { display: "block" } : { display: "none" }
          }
        >
          <div>
            <span className="close" onClick={this.onClose.bind(this)}></span>
            <div>{alertContent}</div>
          </div>
        </div>
      </div>
    );
  }
}
