import React from "react";
import "./index.less";
export default class ScrollList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: [
        { id: "1", message: "恭喜玩家【g05*******79】成功提现1000元", robot: true },
        { id: "2", message: "恭喜玩家【g08*******71】成功提现2000元", robot: false },
        { id: "3", message: "恭喜玩家【g09*******52】成功提现1000元", robot: true },
        { id: "4", message: "恭喜玩家【g11*******93】成功提现1000元", robot: true },
        { id: "5", message: "恭喜玩家【g23*******73】成功提现2000元", robot: true },
        { id: "6", message: "恭喜玩家【g25*******91】成功提现3000元", robot: true },
        { id: "7", message: "恭喜玩家【g33*******51】成功提现2000元", robot: true },
        { id: "8", message: "恭喜玩家【g33*******82】成功提现1000元", robot: true },
        { id: "9", message: "恭喜玩家【g56*******78】成功提现1000元", robot: true },
        { id: "10", message: "恭喜玩家【g56*******10】成功提现2000元", robot: true },
        { id: "11", message: "恭喜玩家【g58*******14】成功提现1000元", robot: true },
        { id: "12", message: "恭喜玩家【g64*******41】成功提现3000元", robot: true },
        { id: "13", message: "恭喜玩家【g78*******45】成功提现3000元", robot: true },
        { id: "14", message: "恭喜玩家【g83*******81】成功提现1000元", robot: true },
      ],
      animate: false,
    };
    this.Dt = this.Dt.bind(this);
  }

  componentDidMount() {
    setInterval(this.Dt, 2000);
  }

  Dt() {
    this.setState({ animate: true }); // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
    setTimeout(() => {
      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
      this.state.numbers.push(this.state.numbers[0]); // 将数组的第一个元素添加到数组的
      this.state.numbers.shift(); //删除数组的第一个元素
      this.setState({ animate: false }); // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      this.forceUpdate();
    }, 1000);
  }

  render() {
    return (
      <div className="cosultation-wrap">
        <a href="/#">
          <div className="cosulation-news">
            <ul className={this.state.animate ? "anim" : ""}>
              {this.state.numbers.map((item, index) => (
                <li className="consulation-news-item" key={item.id}>
                  {item.message}
                </li>
              ))}
            </ul>
          </div>
        </a>
      </div>
    );
  }
}
