import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import queryString from "query-string";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast";

/**
 * 积分兑换活动
 * @author pangzh
 */
export default class ReceivingInfo extends React.Component {
  constructor(props) {
    document.title = "积分兑换";
    super(props);
    this.state = {
      dhList: [],
      showAlert: false,
      rule: "",
      showAlertType: "",
      alertText: "",
      userIntegral: 0, // 积分
      game_id: "",
      activityTime: "",

      uname: "",
      balance: "",
      name: "",
      mobile: "",
      address: "",

      isUpdate: false,
    };
    // this.onAlert = this.onAlert.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log("积分兑换", params);
    get("/activity/getUserInfoRegistration", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        let isUpdate;
        if (
          res.data.data.name &&
          res.data.data.address &&
          res.data.data.mobile
        ) {
          isUpdate = true;
        } else {
          isUpdate = false;
        }

        this.setState({
          balance: res.data.data.balance,
          name: res.data.data.name,
          mobile: res.data.data.mobile,
          uname: res.data.data.uname,
          address: res.data.data.address,
          activityTime: res.data.data.activity_time,
          isUpdate: isUpdate,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery" },
      });
    }
  }
  async onAlert(showAlertType) {
    console.log(showAlertType);
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      if (showAlertType === "record") {
        let res = await get("/activity/integralExchangeRecord", {
          game_id: this.state.game_id,
        });
        console.log(res);
        if (res.code === 0) {
          this.setState({
            showAlert: true,
            showAlertType: showAlertType,
            alertText: res.data.list,
          });
        } else {
          JmAlert.open({
            title: "提示",
            alertTip: res.message,
          });
        }
        return;
      }
      this.setState({
        showAlert: true,
        showAlertType: showAlertType,
        alertText: this.state.rule,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async submitForm() {
    if (!(this.state.name && this.state.address && this.state.mobile)) {
      Toast.fail("请正确填写信息");
    }

    let res = await post(
      `/activity/${
        this.state.isUpdate
          ? "editUserInfoRegistration"
          : "userInfoRegistration"
      }`,
      {
        name: this.state.name,
        mobile: this.state.mobile,
        address: this.state.address,
      }
    );
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      if (this.props.location.query && this.props.location.query.url) {
        window.location.href = this.props.location.query.url;
        return;
      }
      this.props.history.goBack();
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }

  render() {
    return (
      <div className="user_receiving_info">
        <div className="m-input">
          <div>我的米粒</div>
          <div>
            <input
              defaultValue={this.state.balance}
              readOnly
              type="number"
              placeholder="0"
            />
          </div>
        </div>
        <div className="m-input">
          <div>账号</div>
          <div>
            <input
              defaultValue={this.state.uname}
              readOnly
              type="text"
              placeholder="我的账号"
            />
          </div>
        </div>
        <div className="m-input">
          <div>姓名</div>
          <div>
            <input
              maxLength="5"
              name="name"
              value={this.state.name}
              onChange={this.handleInputChange}
              type="text"
              placeholder="请输入真实姓名"
            />
          </div>
        </div>
        <div className="m-input">
          <div>手机号</div>
          <div>
            <input
              name="mobile"
              value={this.state.mobile}
              onChange={this.handleInputChange}
              type="number"
              placeholder="请输入手机号码"
            />
          </div>
        </div>
        <div className="m-input">
          <div>收货地址</div>
          <div>
            <input
              name="address"
              value={this.state.address}
              onChange={this.handleInputChange}
              type="text"
              placeholder="收货地址"
            />
          </div>
        </div>

        <div className="btn-box">
          <div onClick={this.submitForm.bind(this)}>
            {this.state.isUpdate ? "更新" : "保存"}
          </div>
          <div className="tips">
            温馨提示：请认真核对自己的个人信息，否则可能会导致兑换失败或无法收到你的奖品哦
          </div>
        </div>
      </div>
    );
  }
}
