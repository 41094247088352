import React from "react";

import Header from "./components/Header";

export default class CustomeService extends React.Component {
  render() {
    return (
      <div>
        <Header name="客服1"></Header>
      </div>
    );
  }
}
