import { get } from "./axHttp";
export const getUrlActivityBg = (pageName, cssClass) => {
  console.log(pageName);
  if (window.activityBg) {
    if (window.activityBg[pageName]) {
      const bg = document.getElementsByClassName(cssClass);
      bg[0].style.backgroundImage = `url(${window.activityBg[pageName].image})`;
      if (window.activityBg[pageName].color) {
        bg[0].style.backgroundColor = window.activityBg[pageName].color;
      }
    }
  } else {
    if (window.location.href.indexOf("activity") > -1) {
      get("/activity/getActivityPicture", {}).then((res) => {
        if (res.code === 0) {
          console.log(res);
          if (res.data.picture_url) {
            window.activityBg = res.data.picture_url;
            getUrlActivityBg(pageName, cssClass);
          } else {
            window.activityBg = {};
          }
        }
      });
    }
  }
};
