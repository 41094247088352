import React, { Fragment } from "react";
import "./index.less";
// import money from "./images/money.png";
// import rule from "./images/rule.png";
// import redBagPng from "./images/red-bag.png";
import { get, post } from "../../../utils/axHttp";
// import Toast from "../../../components/Toast/index.jsx";
import JmAlert from "../../../components/JmAlert/index.jsx";
import queryString from "query-string";

/**
 * 市场红包页
 */
export default class ortherRedBag extends React.Component {
  constructor(props) {
    super(props);
    document.title = "红包";
    this.state = {
      page: 1,
      cashList: [1000, 2000, 3000],
      cashIndex: 0,
      detail_list: [],
      rule: "",
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    this.setState({ page: params.page });
    get("/activity/redPacketDetail", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({ detail_list: res.data.detail_list });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
          closeAlert: () => {
            console.log("关闭弹窗");
          },
        });
      }
    });
    get("/activity/redPacketRule", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({ rule: res.data.rule });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
          closeAlert: () => {
            console.log("关闭弹窗");
          },
        });
      }
    });
  }
  goBack() {
    window.history.go(-1);
  }
  changeCash(i) {
    this.setState({ cashIndex: i });
  }
  getRedPacketBalance() {
    post("/activity/getRedPacketBalance", {}).then((res) => {
      console.log(res);
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    });
  }
  render() {
    // 单页切换
    let pageContent = "";
    let pageTitle = "";
    switch (this.state.page) {
      case "2":
        pageTitle = "提现";
        pageContent = (
          <div className="getCash">
            <div>
              {this.state.cashList.map((v, i) => {
                return (
                  <div
                    onClick={this.changeCash.bind(this, i)}
                    className={this.state.cashIndex === i ? "active" : ""}
                    key={v}
                  >
                    {v}元
                  </div>
                );
              })}
            </div>
            <div className="btn" onClick={this.getRedPacketBalance.bind(this)}>
              确认提现
            </div>

            <div className="rule">
              提示：
              <br />
              1，现金提现将会在1-3个工作日内将现金发放至绑定的微信钱包中。没有实名开通微信支付的用户将无法提现现金；
              <br />
              2，提现申请一经提交，将无法修改；3，请在活动时间结束前完成提现，活动时间结束后未提现金额将会全部清零。
            </div>
          </div>
        );
        break;
      case "1":
        pageTitle = "红包明细";
        pageContent = (
          <div className="getDetails">
            {this.state.detail_list.map((v, i) => {
              return (
                <div key={i}>
                  <div>
                    <div className="name">{v.name}</div>
                    <div className="time">{v.time}</div>
                  </div>
                  <div>
                    <div className="point">{v.point}</div>
                    <div>余额{v.balance}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
        break;
      case "3":
        pageTitle = "规则";
        pageContent = (
          <div
            className="orther_rule"
            dangerouslySetInnerHTML={{
              __html: this.state.rule,
            }}
          ></div>
        );
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <div className="ac_red_bag">
          <div className="title">
            <span onClick={this.goBack.bind(this)}>◁</span>
            <div>{pageTitle}</div>
          </div>
          {pageContent}
        </div>
      </Fragment>
    );
  }
}
