import React, { Component } from "react";

class NoTimeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      time: 0,
      aFlag: true,
    };
  }
  render() {
    return (
      <span>
        {this.state.day}天 {this.state.hour}:{this.state.minute}:
        {this.state.second}
      </span>
    );
  }

  componentDidUpdate() {
    // console.log(this.props.time, "1");
    // console.log(this.state.time, "2");
    if (this.props.time !== this.state.time) {
      clearInterval(this.timer);
      const end = this.props.time * 1000;
      this.countFun(end);
      this.setState({ time: this.props.time });
      console.log("第一");
    }
  }

  //   static getDerivedStateFromProps(props, state) {
  //     // 父组件传过来的 type 和 子组件的 type 不一样，那么子组件重新赋值。
  //     // 也可以理解成，父组件传过来的值变了。
  //     if (props.time !== state.time) {
  //       const end = props.time * 1000;
  //       return {
  //         time: end,
  //       };
  //     }
  //     return null;
  //   }
  componentDidMount() {
    console.log(this.props.time);
    // const end = Date.parse(new Date("2018-11-29 24:00"));
    const end = this.props.time * 1000;
    this.countFun(end);
  }

  //卸载组件取消倒计时
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countFun = (end) => {
    let now_time = Date.parse(new Date());
    var remaining = end - now_time;

    this.timer = setInterval(() => {
      //防止出现负数
      if (remaining > 1000) {
        remaining -= 1000;
        let day = Math.floor(remaining / 1000 / 3600 / 24);
        let hour = Math.floor((remaining / 1000 / 3600) % 24);
        let minute = Math.floor((remaining / 1000 / 60) % 60);
        let second = Math.floor((remaining / 1000) % 60);

        this.setState({
          day: day,
          hour: hour < 10 ? "0" + hour : hour,
          minute: minute < 10 ? "0" + minute : minute,
          second: second < 10 ? "0" + second : second,
        });
      } else {
        clearInterval(this.timer);
        //倒计时结束时触发父组件的方法
        //this.props.timeEnd();
      }
    }, 1000);
  };
}
export default NoTimeContent;
