import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
// import queryString from "query-string";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast";
import btn from "./image/btn.png";
import colse from "./image/close_hui.png";

/**
 * 占卜活动
 * @author pangzh
 */
export default class Divination extends React.Component {
  constructor(props) {
    document.title = "占卜";
    super(props);
    this.state = {
      page: 0,
      uname: "",
      year: "",
      month: "",
      day: "",
      sex: "",
      resultImage: "",
    };
    // this.onAlert = this.onAlert.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let date = new Date();
    this.setState({
      year: date.getFullYear() + "",
      month: date.getMonth() + 1 + "",
      day: date.getDate() + "",
    });
    // let params = queryString.parse(this.props.location.search);
    // get("/activity/getUserInfoRegistration", {}).then((res) => {
    //   console.log(res);
    //   if (res.code === 0) {
    //     let isUpdate;
    //     if (
    //       res.data.data.name &&
    //       res.data.data.address &&
    //       res.data.data.mobile
    //     ) {
    //       isUpdate = true;
    //     } else {
    //       isUpdate = false;
    //     }

    //     this.setState({
    //       balance: res.data.data.balance,
    //       name: res.data.data.name,
    //       mobile: res.data.data.mobile,
    //       uname: res.data.data.uname,
    //       address: res.data.data.address,
    //       activityTime: res.data.data.activity_time,
    //       isUpdate: isUpdate,
    //     });
    //   } else {
    //     JmAlert.open({
    //       title: "提示",
    //       alertTip: res.message,
    //     });
    //   }
    // });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery", url: window.location.href },
      });
    }
  }
  async onAlert(showAlertType) {
    console.log(showAlertType);
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      if (showAlertType === "record") {
        let res = await get("/activity/integralExchangeRecord", {
          game_id: this.state.game_id,
        });
        console.log(res);
        if (res.code === 0) {
          this.setState({
            showAlert: true,
            showAlertType: showAlertType,
            alertText: res.data.list,
          });
        } else {
          JmAlert.open({
            title: "提示",
            alertTip: res.message,
          });
        }
        return;
      }
      this.setState({
        showAlert: true,
        showAlertType: showAlertType,
        alertText: this.state.rule,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    if (name === "birthday") {
      let arr = value.split("-");
      this.setState({
        year: arr[0],
        month: arr[1],
        day: arr[2],
      });
      return;
    }

    this.setState({
      [name]: value,
    });
  }

  async submitForm() {
    if (!(this.state.year && this.state.month && this.state.sex)) {
      Toast.fail("请正确填写信息");
    }

    let res = await post(`/activity/divination`, {
      birthday: this.state.year + "" + this.state.month + "" + this.state.day,
      gender: this.state.sex,
    });
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      this.setState({ page: 2, resultImage: res.data.divination_result_img });
    } else {
      if (res.code === 44309) {
        this.login();
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    }
    console.log(res);
  }
  closeWebView() {
    console.log("关闭弹窗");
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (
      typeof parent.jimiJS !== "undefined" &&
      parent.jimiJS.closeWebView
    ) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }
  goPage(num) {
    this.setState({
      page: num,
    });
  }

  getToDate() {
    console.log("打印", document.getElementById("toDate"));
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      document.querySelector("#toDate").focus();
      document.querySelector("#toDate").click();
    }
  }

  render() {
    let content;
    switch (this.state.page) {
      case 1:
        content = (
          <div className="input_box page1">
            <div>
              <div className="date_input_box">
                公历：
                <input
                  className="date_input"
                  type="text"
                  readOnly
                  onClick={this.getToDate.bind(this)}
                  value={this.state.year}
                  placeholder="年份"
                />
                年
                <input
                  className="date_input"
                  type="text"
                  readOnly
                  onClick={this.getToDate.bind(this)}
                  value={this.state.month}
                  placeholder="月份"
                />
                月
                <input
                  className="date_input"
                  type="text"
                  readOnly
                  onClick={this.getToDate.bind(this)}
                  value={this.state.day}
                  placeholder="日期"
                />
                日
                <input
                  name="birthday"
                  id="toDate"
                  type="date"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="date_input_box">
                性别：
                <div>
                  <input
                    className="date_radio"
                    onChange={this.handleInputChange}
                    type="radio"
                    name="sex"
                    value="1"
                  />
                  男
                </div>
                <div>
                  <input
                    className="date_radio"
                    onChange={this.handleInputChange}
                    type="radio"
                    name="sex"
                    value="0"
                  />
                  女
                </div>
              </div>
              <div>选定了生日后不可再改了哦~</div>
            </div>
            <img
              className="btn"
              onClick={this.submitForm.bind(this)}
              src={btn}
              alt=""
            />
          </div>
        );
        break;
      case 2:
        content = (
          <div className="divination_image">
            <img src={this.state.resultImage} alt="" />
            <img
              className="colse"
              src={colse}
              onClick={this.closeWebView}
              alt=""
            />
            {/* <img src={dz} alt="" /> */}
          </div>
        );
        break;
      default:
        content = (
          <div className="input_box page">
            <div onClick={this.goPage.bind(this, 1)}></div>
          </div>
        );
    }

    return <div className="activity_divination">{content}</div>;
  }
}
