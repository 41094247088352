/** @format */

import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import lq from "./image/lq.png";
import yjlq from "./image/yjlq.png";
import wdlq from "./image/wdlq.png";
/**
 * 积分兑换活动
 * @author pangzh
 */
export default class Fireworks extends React.Component {
  constructor(props) {
    document.title = "充值返还";
    super(props);
    this.state = {
      miliRebateList: [],
      uname: "",
      showAlert: false,
      activity_time: "",
      get_rebate_time: "",
      current_date: "appointment_date",
      status: 0,
      reward: 0,
      rule: "",
      gear: "",
    };
  }
  componentDidMount() {
    // 1:已领取 2：带岭区 0:没打成
    get("/activity/fireWorksRebateInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          miliRebateList: res.data.list,
          uname: res.data.uname,
          activity_time: res.data.activity_time,
          get_rebate_time: res.data.get_rebate_time,
          current_date: res.data.current_date,
          status: res.data.status,
          reward: res.data.reward,
          rule: res.data.rule,
          gear: res.data.gear,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/czyy", url: window.location.href },
      });
    }
  }
  async join(index) {
    let res = await post("/activity/getFireWorksRebate", {});
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      this.setState({ status: 1 });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    let content = "";
    if (this.state.status === 0) {
      content = (
        <div className="text">
          您一共可获得{this.state.reward}米粒
          <br />
          <img onClick={this.join.bind(this)} src={lq} alt="" />
        </div>
      );
    } else if (this.state.status === 1) {
      content = (
        <div className="text">
          您一共可获得{this.state.reward}米粒
          <br />
          <img src={yjlq} alt="" />
        </div>
      );
    } else {
      content = (
        <div className="text">
          <img src={wdlq} alt="" />
        </div>
      );
    }

    const rule = this.state.rule ? (
      <div
        className="rule"
        dangerouslySetInnerHTML={{
          __html: this.state.rule,
        }}
      ></div>
    ) : (
      <div className="rule">
        <h3>活动规则</h3>
        <ul type="a">
          <li>1、本活动仅限RMB充值</li>
          <li>2、本活动不自动发送，请玩家及时领取</li>
          <li>3、本活动不参与其他返利活动叠加，为单独计算</li>
          <li>4、米粒返利仅计算实际充值金额，满减券减去部分不计入米粒返利范围</li>
        </ul>
      </div>
    );

    const gear = this.state.gear ? (
      <tbody dangerouslySetInnerHTML={{ __html: this.state.gear }}></tbody>
    ) : (
      <tbody></tbody>
    );

    return (
      <div className="AcFireworks">
        <div className="time1">活动时间：{this.state.activity_time || "0000-00-00"}</div>
        <div className="time2">领取时间：{this.state.get_rebate_time || "0000-00-00"}</div>
        <div className="rebate_list">
          <table className="fltable">
            <thead>
              <tr>
                <th>充值元宝范围</th>
                <th>米粒返利比</th>
              </tr>
            </thead>
            {gear}
          </table>
          <div className="btn_box" style={{bottom: "0rem"}}>{content}</div>
        </div>
        {rule}
      </div>
    );
  }
}
