import React from "react";
import { Fragment } from "react";
import manImg from "../image/phone/man.png";
import womenImg from "../image/phone/women.png";
export default class PhonePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.vi = React.createRef();
    this.viBox = React.createRef();
    this.playVideo = this.playVideo.bind(this);
    this.cover = props.cover;
  }

  componentDidMount() {
    this.vi.current.setAttribute("playsinline", "true");
  }

  playVideo() {
    console.log("播放视频");
    console.log(this.vi);
    this.viBox.current.style.position = "fixed";
    this.viBox.current.style.top = 0;
    this.viBox.current.style.left = 0;
    this.viBox.current.style.right = 0;
    this.viBox.current.style.bottom = 0;
    this.viBox.current.style.zIndex = 1;
    this.vi.current.play();
    this.props.onStatus(2);
    this.vi.current.onended = () => {
      this.props.onStatus(3);
      if (this.props.isJump) {
        document.querySelector(".toUrl").style.display = "block";
      }
    };
  }

  render() {
    let figureImg;
    if (this.props.sex === "women") {
      figureImg = <img className="women" src={womenImg} alt="" />;
    } else {
      figureImg = <img className="man" src={manImg} alt="" />;
    }
    return (
      <Fragment>
        <div className="phone">
          <div>
            <div className="vi_box" ref={this.viBox}>
              <video
                poster={this.cover}
                preload="true"
                controls={false}
                webkit-playsinline="true"
                x-webkit-airplay="true"
                src={this.props.tag}
                ref={this.vi}
              ></video>
              {/* <img src="" alt="" ref={this.vi} /> */}
            </div>
            {figureImg}
          </div>
          <div>
            <div onClick={this.playVideo}>
              <img src={require("../image/phone/jdh.png")} alt="" />
              <span>接听电话</span>
            </div>
            <div onClick={() => this.props.close()}>
              <img src={require("../image/phone/gdh.png")} alt="" />
              <span>挂断电话</span>
            </div>
          </div>
        </div>
        {/* <div className="toUrl" onClick={() => this.props.link()}>
          点击查看详情
        </div> */}
        <img className="toUrl" onClick={() => this.props.link()} src={require("../image/btn.png")} alt="" />
      </Fragment>
    );
  }
}
