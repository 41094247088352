import React from "react";
import "./style.less";
import closePng from "./image/colse.png";
// import { get } from "../../../utils/http";
import queryString from "query-string";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class Gift extends React.Component {
  constructor(props) {
    document.title = "礼包活动"; // 修改标题
    super(props);
    this.state = { date: new Date(), giftInfo: [] };
  }
  componentDidMount() {
    // 获取链接参数
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    let giftInfo = [];
    if (params.gift_info) {
      giftInfo = params.gift_info.split(",");
    }
    console.log(giftInfo);
    this.setState({ giftInfo: giftInfo });
  }
  closeWebView() {
    console.log("1232")
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (
      typeof parent.jimiJS !== "undefined" &&
      parent.jimiJS.closeWebView
    ) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }
  copy() {
    alert("复制成功！");
  }
  render() {
    return (
      <div className="gift1-page">
        <div className="title">
          恭喜您获得限时活动礼包码。复制之后，进入游戏，点击福利-礼包兑换-点击输出礼包码，领取即可
        </div>
        <div className="gift-box">
          {this.state.giftInfo.map((item, index) => (
            <div className="gift-item" key={item}>
              <span>礼包{index + 1}</span>
              <input type="text" value={item} disabled />
              <CopyToClipboard
                text={item} //点击复制时的内容,可自行设置或传入
                onCopy={this.copy} //点击之后的回调
              >
                <button key="copy">复制</button>
              </CopyToClipboard>
            </div>
          ))}
        </div>
        <div className="desc">
          备注：如果您忘记了礼包码或者查看礼包详细内容，请点击悬浮窗图标-福利-礼包，可以查看复制已领取礼包码
        </div>
        <div className="bg"></div>
        <div className="close-box" onClick={this.closeWebView}>
          <img src={closePng} alt="" />
        </div>
      </div>
    );
  }
}
