import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import { getUrlActivityBg } from "../../../utils/tools";
import Toast from "../../../components/Toast/index.jsx";
import mili from "./image/mili.png";
import libao from "./image/libao.png";
import rule from "./image/rule.png";
// import ylq from "./image/ylq.png";
// import wlq from "./image/wlq.png";
import RuleAlert from "../../../components/ruleAlert/index.jsx";

/**
 * 积分兑换活动
 * @author pangzh
 */
export default class MiliReturn extends React.Component {
  constructor(props) {
    document.title = "充值返还";
    super(props);
    this.state = {
      miliRebateList: [],
      uname: "",
      rule: "",
      showAlert: false,
      alertRecord: [],
    };
  }
  componentDidMount() {
    getUrlActivityBg("mili_rebate", "mili_return");
    // 1:已领取 2：带岭区 0:没打成
    get("/activity/miliRebateList", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          miliRebateList: res.data.list,
          rule: res.data.rule,
          uname: res.data.uname,
          activityTime: res.data.activity_time,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery", url: window.location.href },
      });
    }
  }
  async getMiliRebate(index) {
    if (this.state.miliRebateList[index].status !== 2) {
      // JmAlert.open({
      //   title: "提示",
      //   alertTip: "已经领取过了，请勿重复兑换",
      // });
      return;
    }
    let res = await post("/activity/getMiliRebate", {
      level: this.state.miliRebateList[index].level,
    });
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
        copy: true,
      });
      let miliRebateList = this.state.miliRebateList;
      miliRebateList[index].status = 1;
      this.setState({
        miliRebateList: miliRebateList,
      });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    return (
      <div className="mili_return">
        <div className="rebate_list">
          {this.state.miliRebateList.map((v, i) => {
            return (
              <div key={i}>
                <img className="libao" src={libao} alt="" />
                <div className="mili_box">
                  <img className="mili" src={mili} alt="" />
                  <span>*{v.mili}</span>
                </div>
                <div className="text">单笔充值{v.level}元</div>
                <img className="libao" src={libao} alt="" />
                <div onClick={this.getMiliRebate.bind(this, i)} className={`btn ${v.status === 2 ? "wlq" : "ylq"}`}>
                  {{ 0: "未达成", 1: "已领取", 2: "立即领取" }[v.status]}
                </div>
              </div>
            );
          })}
        </div>
        <div className="alert">
          <div onClick={this.onAlert.bind(this)}></div>
        </div>
        <div className="time">{this.state.activityTime}</div>
        <RuleAlert
          bg={rule}
          showAlertType="rule"
          text={this.state.rule}
          showAlert={this.state.showAlert}
          close={this.onAlert.bind(this)}
        />
      </div>
    );
  }
}
