import React from "react";
import ReactDOM from "react-dom";
import wx from "./images/wx.png";
import al from "./images/al.png";

export default class PayChange {
  static NodeId = "paychange";

  static pay = (type, callBack) => {
    callBack(type);
    this.close();
  };

  static open = (params) => {
    if (document.getElementById(this.NodeId)) {
      return;
    }
    const div = document.createElement("div");
    div.id = this.NodeId;
    document.body.appendChild(div);
    ReactDOM.render(
      <div>
        <div className="title">选择支付类型</div>
        <div className="content">
          <div onClick={this.pay.bind(this, "wechath5", params.pay)}>
            <img src={wx} alt="" />
            微信支付
          </div>
          <div onClick={this.pay.bind(this, "alipayh5", params.pay)}>
            <img src={al} alt="" />
            支付宝
          </div>
        </div>
        <div className="btns">
          <div onClick={this.close}>取消</div>
        </div>
      </div>,
      div
    );
  };

  static close = () => {
    const modal = document.getElementById(this.NodeId);
    document.body.removeChild(modal);
  };
}
