import React from "react";
import "./style.less";
// import Logo from "./images/logo.png";

export default class Gift extends React.Component {
  constructor(props) {
    document.title = "战神榜"; // 修改标题
    super(props);
    this.state = {
      date: new Date(),
      giftInfo: [],
      monthList: [7, 8, 9, 10, 11],
      currMonth: 9,
      rankData: {
        11: [
          ["仙境295服", "向曰蝰", "48069056"],
          ["仙境303服", "酒剑仙", 46820696],
          ["仙境295服", "侠盗", 34915798],
          ["仙境317服", "韩非", 28785350],
          ["仙境295服", "冷枫", 27253379],
          ["仙境314服", "羊村村长", 26932578],
          ["仙境333服", "苏凌峰", 25556621],
          ["仙境313服", "珠江之歌", 23308729],
          ["仙境323服", "挚爱小可爱", 22034252],
          ["仙境299服", "超帅的刚子", 21206293],
        ],
        10: [
          ["仙境248服", "宝儿°", "201602582"],
          ["仙境224服", "心有林夕", 103767060],
          ["仙境271服", "右右", 100343077],
          ["仙境239服", "花一样的男子", 96321927],
          ["仙境229服", "二哥", 88828038],
          ["仙境259服", "萧寒", 88090129],
          ["仙境226服", "小籹人的大侽人", 82368797],
          ["仙境229服", "三哥", 78199779],
          ["仙境240服", "九天", 77623060],
          ["仙境249服", "君昊", 72421834],
        ],
        9: [
          ["仙境132服", "小权", "471790468"],
          ["仙境143服", "皇帝哥哥", 349365494],
          ["仙境160服", "夜魔", 339802179],
          ["仙境156服", "浪尼姑", 267490223],
          ["仙境173服", "凌夜", 253417588],
          ["仙境189服", "珞珞", 252850131],
          ["仙境191服", "青风", 199294452],
          ["仙境199服", "老阮", 192141087],
          ["仙境182服", "龙灬少", 186710349],
          ["仙境201服", "向曰蝰", 184585513],
        ],
        8: [
          ["仙境70服", "女神丶举世无双", "1106780479"],
          ["仙境66服", "那年烟花", 890398192],
          ["仙境88服", "微微一笑很倾城", 671643472],
          ["仙境93服", "仙｜梦", 585356462],
          ["仙境90服", "土匪头子。", 513441790],
          ["仙境111服", "帥氣的灬浩哥", 254387780],
          ["仙境111服", "萍", 181445990],
          ["仙境88服", "奈何一笑很倾心", 176987130],
          ["仙境117服", "爱慕夜雨", 116174564],
        ],
        7: [
          ["仙境26服", "天星、豪", "494018878"],
          ["仙境61服", "战斧灬楚", 428736813],
          ["仙境13服", "打小不讲理", 248133562],
        ],
      },
    };
  }
  checkMonth(v) {
    console.log(v);
    this.setState({ currMonth: v });
  }
  componentDidMount() {
    console.log();
  }
  render() {
    return (
      <div className="ac_ranking">
        {/* <img className="logo" src={Logo} alt="" /> */}
        <div className="month">
          {this.state.monthList.map((v) => {
            return (
              <div
                key={v}
                onClick={this.checkMonth.bind(this, v)}
                className={v === this.state.currMonth ? "curr_month" : ""}
              >
                {v}月份
              </div>
            );
          })}
        </div>
        <ul className="rank_list">
          {this.state.rankData[this.state.currMonth].map((v, i) => {
            return (
              <li key={i}>
                <div>{v[1]}</div>
                <div>{v[0]}</div>
                <div>{v[2]}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
