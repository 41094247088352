import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import p666 from "./image/666.png";
import p6666 from "./image/6666.png";
import p8888 from "./image/8888.png";
import p12888 from "./image/12888.png";

/**
 * 积分兑换活动
 * @author pangzh
 */
export default class MiliMakeVow extends React.Component {
  constructor(props) {
    document.title = "米粒许愿";
    super(props);
    this.state = {
      wishPrizeList: [],
      wish_status: 0,
      wish_reward: 0,
      uname: "",
      rule: "",
      showAlert: false,
      alertRecord: [],
      prize: "",
    };
  }
  componentDidMount() {
    //wish_status 许愿状态 0无资格 1是待许愿 2是已许愿 3奖励待发放 4奖励发放完毕
    get("/activity/wishInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          wishPrizeList: res.data.wish_prize_list,
          rule: res.data.rule,
          uname: res.data.uname,
          wish_status: res.data.wish_status,
          wish_reward: res.data.wish_reward,
          activityTime: res.data.activity_time,
          openTime: res.data.reward_time,
          prize: res.data.prize,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery", url: window.location.href },
      });
    }
  }
  async postMake(index) {
    let res = await post("/activity/makeWish", {});
    if (res.code === 0) {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
      this.setState({ wish_status: 2 });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    let content;
    switch (this.state.wish_status) {
      case 0:
        content = (
          <div>
            <div className="jp">
              <img src={p12888} alt="" />
              <img src={p8888} alt="" />
              <img src={p6666} alt="" />
            </div>
            <div className="btn ylq">
              累充328RMB
              <br />
              进行许愿
            </div>
          </div>
        );
        break;
      case 1:
        content = (
          <div>
            <div className="jp">
              <img src={p12888} alt="" />
              <img src={p8888} alt="" />
              <img src={p6666} alt="" />
            </div>
            <div className="btn btn1 wlq" onClick={this.postMake.bind(this)}>
              开始许愿
            </div>
          </div>
        );
        break;
      case 2:
        content = (
          <div>
            <div className="jp">
              <img src={p12888} alt="" />
              <img src={p8888} alt="" />
              <img src={p6666} alt="" />
            </div>
            <div className="btn btn1 ylq">已许愿</div>
          </div>
        );
        break;
      default:
        content = (
          <div>
            <div className="jp">
              <img
                src={
                  { 12888: p12888, 666: p666, 6666: p6666, 8888: p8888 }[
                    this.state.wish_reward
                  ]
                }
                alt=""
              />
            </div>
            <div className="dj">
              恭喜您获得{this.state.prize}
              <br />
              奖励已自动发放
            </div>
          </div>
        );
    }
    return (
      <div className="mili_make_vow">
        <div className="rebate_list">
          <div>
            <p>
              1、许愿时间：{this.state.activityTime} 。
            </p>
            <p>2、获奖名单公示时间：{this.state.openTime} 公示获奖账号信息。</p>
          </div>
          <div>{content}</div>
          <div
            className="rule"
            dangerouslySetInnerHTML={{ __html: this.state.rule }}
          ></div>
          <div className="wish_prize_list">
            <div>
              <div>排名</div>
              <div>账号</div>
              <div>奖励</div>
            </div>
            <div>
              {this.state.wishPrizeList.map((v) => {
                return (
                  <div key={v.reward}>
                    <div>{v.ranking}</div>
                    <div>{v.uname}</div>
                    <div>{v.reward}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
