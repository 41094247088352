import React from "react";
import "./style.less";
// import JmAlert from "../../components/JmAlert/index.jsx";
// import queryString from "query-string";
// import Toast from "../../components/Toast";
import ahcsbl from "./image/ahcsbl.png";
import qyzbbb from "./image/qyzbbb.png";
import sgxy from "./image/sgxy.png";
import jzjz from "./image/jzjz.png";
import cqsjzzjty from "./image/cqsjzzjty.png";
import jxtw from "./image/jxtw.png";
import xz from "./image/xz.png";
/**
 * 积分兑换活动
 * @author pangzh
 */
export default class GameCenter extends React.Component {
  constructor(props) {
    document.title = "游戏中心";
    super(props);
    this.state = {
      uname: "",
      balance: "",
      name: "",
      mobile: "",
      address: "",

      isUpdate: false,
    };
    // this.onAlert = this.onAlert.bind(this);
    // this.goLink = this.goLink.bind(this);
  }
  componentDidMount() {
    // let params = queryString.parse(this.props.location.search);
    // console.log("积分兑换", params);
    // get("/activity/getUserInfoRegistration", {}).then((res) => {
    //   console.log(res);
    //   if (res.code === 0) {
    //     let isUpdate;
    //     if (
    //       res.data.data.name &&
    //       res.data.data.address &&
    //       res.data.data.mobile
    //     ) {
    //       isUpdate = true;
    //     } else {
    //       isUpdate = false;
    //     }
    //     this.setState({
    //       balance: res.data.data.balance,
    //       name: res.data.data.name,
    //       mobile: res.data.data.mobile,
    //       uname: res.data.data.uname,
    //       address: res.data.data.address,
    //       activityTime: res.data.data.activity_time,
    //       isUpdate: isUpdate,
    //     });
    //   } else {
    //     JmAlert.open({
    //       title: "提示",
    //       alertTip: res.message,
    //     });
    //   }
    // });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery", url: window.location.href },
      });
    }
  }

  async goLink(url) {
    window.open(url);
  }

  render() {
    return (
      <div className="game_center">
        <div className="box">
          <div>
            <div>
              <img className="icon" src={qyzbbb} alt="" />
              <div>青云传红包版</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://p.ml5i.com/html/5/5166.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
            <div>
              <img className="icon" src={sgxy} alt="" />
              <div>上古仙缘</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://xxx.o22n.com/html/5/5164.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
          </div>
          <div>
            <div>
              <img className="icon" src={jzjz} alt="" />
              <div>九州剑宗</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://p.ml5i.com/html/5/5212.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
            <div>
              <img className="icon" src={cqsjzzjty} alt="" />
              <div>传奇世界之仗剑天涯</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://p.ml5i.com/html/5/5209.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
          </div>
          <div>
            <div>
              <img className="icon" src={jxtw} alt="" />
              <div>极限逃亡</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://p.ml5i.com/html/5/5210.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
            <div>
              <img className="icon" src={ahcsbl} alt="" />
              <div>暗黑超神部落</div>
              <img
                onClick={this.goLink.bind(
                  this,
                  "https://p.ml5i.com/html/5/5211.html"
                )}
                className="xz"
                src={xz}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
