import axios from "axios"; // 引入axios
import queryString from "query-string";

export default class HttpUtils {
  static baseUrl = process.env.NODE_ENV === "production" ? "" : "/api"; //测试
  static get = (url, params = {}, self = "") => {
    if (self) {
      let urlParams = queryString.parse(self.props.location.search);
      console.log(urlParams);
      if (urlParams.access_token && url.indexOf("access_token") < 0) {
        if (url.indexOf("?") > -1) {
          url += "&";
        } else {
          url += "?";
        }
        url += "access_token=" + urlParams.access_token;
      }
      urlParams.game_id &&
        (url +=
          (url.indexOf("?") > -1 ? "&" : "?") +
          ("game_id=" + urlParams.game_id));
    } else {
      if (this.getCookie("yj_mh_game_access_token")) {
        if (url.indexOf("access_token") < 0) {
          if (url.indexOf("?") > -1) {
            url += "&";
          } else {
            url += "?";
          }
          url += "access_token=" + this.getCookie("yj_mh_game_access_token");
        }
      }
    }
    return new Promise((resolve, reject) => {
      //resolve 和 reject 函数被调用时，分别将promise的状态改为fulfilled（完成）或rejected（失败）
      fetch(this.baseUrl + url) //默认是GET
        .then((response) => response.json()) //把数据解析成json格式,然后取出
        .then((result) => {
          resolve(result); //表示完成
        })
        .catch((error) => {
          reject(error); //表示失败
        });
    });
  };
  static post = (url, data, self = "") => {
    if (self) {
      let urlParams = queryString.parse(self.props.location.search);
      urlParams.access_token && (data.access_token = urlParams.access_token);
      urlParams.game_id && (data.game_id = urlParams.game_id);
    }
    return new Promise((resolve, reject) => {
      fetch(
        this.baseUrl +
          url +
          "?access_token=" +
          this.getCookie("yj_mh_game_access_token"),
        {
          method: "POST",
          headers: {
            // Accept: "application/json", //告诉服务器，我们能接受json格式的返回类型，
            "Content-Type": "application/json; charset=utf-8", //告诉服务器，我们提交的数据类型
          },
          body: JSON.stringify(data), //(把你想提交得数据序列化为json字符串类型，然后提交)body中的数据就是我们需要向服务器提交的数据,比如用户名,密码等
        }
      ) //返回 服务器处理的结果
        .then((response) => response.json())
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  static getCookie(name) {
    /* eslint-disable */
    let arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    return (arr = document.cookie.match(reg)) ? unescape(arr[2]) : null;
    /* eslint-disable */
  }
  static setCookie(name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  }

  /**
   * 设置cookie
   * @param {string} name  键名
   * @param {string} value 键值
   * @param {integer} days cookie周期
   */
  static setCookie2(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else {
      var expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  static ajax_post(url, data) {
    return new Promise((resolve, reject) => {
      let ajax = new XMLHttpRequest();
      ajax.open("post", this.baseUrl + url);
      ajax.setRequestHeader(
        // "Content-type",
        // "application/x-www-form-urlencoded"
        "Content-Type",
        "application/json; charset=utf-8"
      );
      if (data) {
        ajax.send(JSON.stringify(data));
      } else {
        ajax.send();
      }
      ajax.onreadystatechange = function () {
        if (ajax.readyState == 4 && ajax.status == 200) {
          resolve(JSON.parse(ajax.responseText));
        } else {
          reject();
        }
      };
    });
  }
  static ajax_get(url, data) {
    return new Promise((resolve, reject) => {
      let ajax = new XMLHttpRequest();
      //url方法，如果发送数据段格式为xxx。php？name=jack&age=18，需要拼接
      if (data) {
        url += "?";
        url += data;
      } else {
      }
      ajax.open("get", this.baseUrl + url);
      ajax.send();
      ajax.onreadystatechange = () => {
        if (ajax.readyState == 4 && ajax.status == 200) {
          resolve(JSON.parse(ajax.responseText));
        } else {
          reject();
        }
      };
    });
  }

  static axios_post(url, data) {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  static getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    var strValue = "";
    if (r != null) {
      strValue = unescape(r[2]);
    }
    return strValue;
  }
}
