import React from "react";
import "./style.less";
import Logo from "./images/logo.png";
import Title from "./images/title.png";
import p1Xyzp from "./images/p1_xyzp.png";
import start from "./images/start.png";
import queryString from "query-string";
import ZP from "../../../components/ZP/index.jsx";
import HttpUtils from "../../../utils/HttpUtils";

export default class Gift extends React.Component {
  constructor(props) {
    document.title = "幸运大抽奖"; // 修改标题
    super(props);
    this.state = {
      date: new Date(),
      giftInfo: [],
      content: 0,
      username: "",
      balance: "",
      access_token: "",
      page2Record: [],
      page4Msg: "",
      gameId: "",
      activityTime: "",
    };
    this.onRecord = this.onRecord.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onRule = this.onRule.bind(this);
    this.gameIdLogin = this.gameIdLogin.bind(this);
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    if (params.access_token) {
      console.log("登录成功");
      HttpUtils.setCookie("yj_mh_game_access_token", params.access_token);
      HttpUtils.get(
        "/activity/miliTurnNum?access_token=" + params.access_token,
        {},
        this
      )
        .then((res) => {
          console.log(res);
          this.setState({
            username: res.data.user_name,
            availableNum: res.data.available_num,
            access_token: params.access_token,
            activityTime: res.data.activity_time,
            rule: res.data.rule,
            gameId: params.game_id,
          });
        })
        .catch(() => {});
    } else if (params.game_id) {
      // 没有登录
      this.gameIdLogin(params.game_id);
    }
  }

  async gameIdLogin(gameId) {
    let res = await HttpUtils.get(
      "/activity/miliTurnNum?access_token=" +
        this.state.access_token +
        "&game_id=" +
        gameId
    );
    console.log(res);
    if (res.code === 0) {
      this.setState({
        username: res.data.user_name,
        availableNum: res.data.available_num,
        rule: res.data.rule,
        activityTime: res.data.activity_time,
        gameId: gameId,
      });
    } else if (res.code === 44309) {
      setTimeout(() => {
        this.props.history.push({
          pathname: "/login",
          query: { backUrl: "/activity/lottery" },
        });
      }, 1000);
    }
  }

  onAction(callback) {
    HttpUtils.get(
      "/activity/mili_turn?access_token=" + this.state.access_token,
      {},
      this
    )
      .then((v) => {
        console.log(v);
        if (v.code === 0) {
          callback(v.data);
          this.setState({
            balance: v.data.balance,
            availableNum: this.state.availableNum - 1,
          });
        } else {
          this.setState({ page4Msg: v.message, content: 4 });
        }
      })
      .catch(() => {});
  }

  onStop() {
    console.log("停止");
    this.setState({ content: 2 });
  }

  onStart() {
    this.setState({ content: 1 });
  }

  onRule() {
    if (this.state.alertStatus) {
      this.setState({ alertStatus: false });
    } else {
      this.setState({ alertStatus: true });
    }
  }

  onRecord() {
    HttpUtils.get(
      "/activity/miliTurnRecord?access_token=" + this.state.access_token,
      {},
      this
    )
      .then((v) => {
        this.setState({ page2Record: v.data });
      })
      .catch(() => {});
    this.setState({ content: 3 });
  }
  render() {
    let content = this.state.content || "";
    switch (content) {
      case 1:
        content = (
          <div className="page1">
            <div className="knowuname">
              亲爱的{this.state.username}
              {/* <span onClick={this.onRecord}>抽奖记录</span> */}
            </div>
            <img className="logo" src={Logo} alt="" />
            <img className="title" src={Title} alt="" />
            <div className="number">
              今天还可以抽奖<span>{this.state.availableNum}</span>次
            </div>
            <div className="zjl">中奖率（100%）</div>
            <ZP
              stop={this.onStop.bind(this)}
              action={(callback) => {
                this.onAction(callback);
              }}
            />
            <div className="time">{this.state.activityTime}</div>
            <div className="rule" onClick={this.onRule}>
              活动规则
            </div>
            <div className="record" onClick={this.onRecord}>
              抽奖记录
            </div>

            <div
              className="rule_box"
              style={
                this.state.alertStatus
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={this.onRule}
            >
              <div className="rule_text">
                <div>活动规则</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.rule,
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
        break;
      case 2:
        content = (
          <div className="page2" onClick={this.onStart}>
            <div className="prop_box1 zjxx">
              <div className="prop_box1_title">恭喜获得加倍米粒</div>
              <div>GONG XI HUO DE JIA BEI MI LI</div>
              <div className="mili">{this.state.balance}米粒</div>
            </div>
          </div>
        );
        break;
      case 3:
        content = (
          <div className="page3" onClick={this.onStart}>
            <div className="prop_box1 zjxx">
              <div className="prop_box1_title">抽奖记录</div>
              <div>CHOU JIANG JI LU</div>
              <div className="text name">
                亲爱的{this.state.username}，<span>继续抽奖</span>
              </div>
              <div className="text recod">
                {this.state.page2Record.map((v, i) => {
                  return (
                    <div key={i}>
                      日期{v.date} {v.mili}
                    </div>
                  );
                })}
              </div>
              <div className="text rule">
                <div>领取规则：</div>
                <div>您获取的米粒将直接到账到您的账户</div>
              </div>
            </div>
          </div>
        );
        break;
      case 4:
        content = (
          <div onClick={this.onStart} className="page4">
            <div className="tip_box">{this.state.page4Msg}</div>
          </div>
        );
        break;
      default:
        content = (
          <div className="page0">
            <img className="logo" src={Logo} alt="" />
            <img className="title" src={p1Xyzp} alt="" />
            <img onClick={this.onStart} className="start" src={start} alt="" />
          </div>
        );
    }
    return <div className="lottery_box">{content}</div>;
  }
}
