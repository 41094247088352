import colse from "./image/close_hui.png";
import React from "react";
// import { put, get } from "../../../utils/http";
import HttpUtils from "../../../utils/HttpUtils";
import queryString from "query-string";
import "./style.less";
import PhonePopup from "./components/phone-popup";
import LetterPopup from "./components/letter-popup";
import { VideoPopup } from "./components/video-popup";

export default class Window extends React.Component {
  constructor(props) {
    document.title = "广告"; // 修改标题
    super(props);
    this.state = { date: new Date(), giftInfo: [], tag: "", type: 1, link: "" };
    this.popupStatistical = this.popupStatistical.bind(this);
  }
  closeWebView() {
    console.log("关闭弹窗");
    this.popupStatistical(1);
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (typeof parent.jimiJS !== "undefined" && parent.jimiJS.closeWebView) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params) {
      console.log(params);
      console.log("有图片");
      this.setState({
        tag: params.tag,
        popupId: params.popup_id,
        popupType: params.popup_type,
        videoCover: params.cover_tag,
        type: params.type,
        sex: params.show_sex || "",
        showClose: true,
      });
    }
    if (params.popup_id && params.popup_type) {
      console.log();
      HttpUtils.get("/Popup/getPopupJumpUrl?popup_id=" + params.popup_id)
        .then((res) => {
          console.log("链接返回===》", res);
          if (res.code === 0) {
            this.setState({
              link: res.data,
            });
          }
        })
        .catch((error) => {});

      this.state.onlineTime = 0;
      this.state.isPopupStatistical = false;
      this.state.onInterval = setInterval(() => {
        this.state.onlineTime++;
      }, 1000);
    }
  }

  popupStatistical(isJump) {
    HttpUtils.post("/Popup/PopupStatistical", {
      popup_id: this.state.popupId,
      popup_type: this.state.popupType,
      popup_online_time: this.state.onlineTime,
      is_jump: isJump,
    })
      .then((result) => {
        console.log(result);
        window.clearInterval(this.state.onInterval);
        this.state.isPopupStatistical = true;
      })
      .catch((error) => {});
  }
  goLink() {
    console.log("跳转");
    if (this.state.link && !this.state.isPopupStatistical) {
      this.popupStatistical(0);
    }
    // loadUrlByBrowser(this.state.link);
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("loadUrlByBrowser", this.state.link);
    } else if (typeof jimiJS !== "undefined" && jimiJS?.loadUrlByBrowser) {
      jimiJS.loadUrlByBrowser(this.state.link);
    }
    /* eslint-disable */
  }
  componentWillUnmount() {
    console.log("卸载页面");
  }
  render() {
    let content;
    let showClose = this.state.showClose;
    switch (this.state.type) {
      case "1":
        content = (
          <div className="bg-box">
            <img className="bg" onClick={this.goLink.bind(this)} src={this.state.tag} alt="" />
          </div>
        );
        break;
      case "2":
        content = (
          <LetterPopup
            close={this.closeWebView.bind(this)}
            tag={this.state.tag}
            sex={this.state.sex}
            isJump={this.state.link ? true : false}
            link={this.goLink.bind(this)}
            onStatus={this.popupStatistical}
            cover={this.state.videoCover}
          />
        );
        break;
      case "3":
        content = (
          <PhonePopup
            close={this.closeWebView.bind(this)}
            tag={this.state.tag}
            sex={this.state.sex}
            isJump={this.state.link ? true : false}
            link={this.goLink.bind(this)}
            onStatus={this.popupStatistical}
            cover={this.state.videoCover}
          />
        );
        break;
      case "4":
        showClose = false;
        content = (
          <VideoPopup
            tag={this.state.tag}
            onClose={this.closeWebView.bind(this)}
            onStatus={this.popupStatistical}
            cover={this.state.videoCover}
          ></VideoPopup>
        );
    }

    return (
      <div className="win">
        <img
          style={{ display: showClose ? "block" : "none" }}
          className="colse"
          src={colse}
          onClick={this.closeWebView.bind(this)}
          alt=""
        />
        {content}
      </div>
    );
  }
}
