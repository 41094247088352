import React from "react";
import "./style.less";
import HttpUtils from "../../../utils/HttpUtils";
import queryString from "query-string";
import Alert from "../../../components/Alert/alert.jsx";
import ZQ from "./zqbox.jsx";
class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      questionList: [],
      answerData: {},
      id: "",
      showWarning: false,
      giftCode: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  componentDidMount() {
    document.title = "问卷调查";
    let params = queryString.parse(this.props.location.search);
    this.setState({ id: params.id });
    HttpUtils.get("/Questionnaire/getQuestionnaire?id=" + params.id, "")
      .then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.setState({ questionList: res.data.question_list });
        } else if (res.code === 1) {
          //   this.props.history.push("/login");
          setTimeout(() => {
            this.props.history.push({
              pathname: "/login",
              query: { backUrl: "/activity/questionnaire" },
            });
          }, 200);
        } else {
          Alert.open({
            alertTip: res.message,
            closeAlert: function () {
              console.log("关闭了...");
            },
          });
        }
      })
      .catch(() => {});
  }
  handleInputChange(event) {
    const target = event.target;
    let answerData = this.state.answerData;
    answerData[target.name] = target.value;
    this.setState({ answerData: answerData });
  }
  handleCheckBoxChange(event) {
    const target = event.target;
    let answerData = this.state.answerData;
    console.log(target);
    console.log(target.checked);
    if (!answerData[target.name]) {
      answerData[target.name] = [];
    } else {
      answerData[target.name] = answerData[target.name].split(",");
    }
    if (target.checked) {
      answerData[target.name].push(target.value);
    } else {
      answerData[target.name].splice(
        answerData[target.name].indexOf(target.value),
        1
      );
    }
    answerData[target.name] = answerData[target.name].join(",");
  }
  submit() {
    if (
      Object.keys(this.state.answerData).length <
      this.state.questionList.length
    ) {
      Alert.open({
        alertTip: "请确认全部正确填写",
      });
      return;
    }

    let params = { id: this.state.id, answerData: [] };
    Object.keys(this.state.answerData).forEach((v) => {
      console.log(v);
      params.answerData.push({
        question_id: v,
        option_id: this.state.answerData[v],
      });
    });
    console.log(params);
    HttpUtils.post("/Questionnaire/answerQuestionnaire", params).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({ showWarning: true, giftCode: res.data.gift_code });
      } else {
        Alert.open({
          alertTip: res.message,
          closeAlert: function () {
            console.log("关闭了...");
          },
        });
      }
    });
  }
  close() {
    this.setState({ showWarning: false });
  }
  render() {
    return (
      <div className="ActQuestionnaire">
        <div className="banner"></div>
        <div className="question">
          <div>
            <div className="tip">
              根据中国法律法规，未经过您的许可，我们不会使用或分享您的个人信息我们会将您的所有信息保密。
            </div>
            <div className="content">
              {this.state.questionList.map((v, i) => {
                return (
                  <div className="item" key={v.id}>
                    <div className="title">
                      <div className="icon">
                        <span>{i + 1}</span>
                      </div>
                      <div>
                        {v.question}[{v.type === 1 ? "单选题" : "多选题"}]
                      </div>
                    </div>
                    <form className="radio_list">
                      {v.option_list.map((item) => {
                        if (v.type === 1) {
                          return (
                            <div key={item.id}>
                              <input
                                type="radio"
                                name={v.id}
                                value={item.id}
                                onChange={this.handleInputChange}
                              />
                              <span>{item.option_info}</span>
                            </div>
                          );
                        } else {
                          return (
                            <div key={item.id}>
                              <input
                                type="checkbox"
                                name={v.id}
                                value={item.id}
                                onChange={this.handleCheckBoxChange}
                              />
                              <span>{item.option_info}</span>
                            </div>
                          );
                        }
                      })}
                    </form>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="btn-box">
            <div className="btn" onClick={this.submit.bind(this)}>
              提交问卷
            </div>
            <div>感谢您的参与，请提交问卷</div>
          </div>
        </div>
        <ZQ
          code={this.state.giftCode}
          warn={this.state.showWarning}
          close={() => {
            this.close();
          }}
        />
      </div>
    );
  }
}

export default Questionnaire;
