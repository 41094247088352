
import React from 'react'
import './style.less'
import bg1010 from './image/bg1010.jpg'
import lq from './image/lq-720x130.jpg'
import btn from './image/btn.png'
import { get } from '../../../utils/http'
import queryString from 'query-string'

export default class Download extends React.Component {
    constructor(props) {
        document.title = '下载' // 修改标题
        super(props);
        this.state = { date: new Date() };
    }
    componentDidMount() {
        let u = navigator.userAgent;
        // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        this.setState({ isiOS: isiOS })
        // 获取链接参数
        let params = queryString.parse(this.props.location.search)
        console.log(params)
        get("/task_point/downloadInfo", {
            inviter_code: params.sub,
            package_campaign: params.package_campaign
        }).then((res) => {
            console.log(res)
            this.setState(res.data)
            console.log(this.state)
        })
    }
    render() {
        return (
            <div className="download_page">
                <div className="header">
                    <img className="logo" src={this.state.icon_url} alt="" />
                    <span>{this.state.game_name}</span>
                    <img className="download-btn" src={btn} alt="" />
                </div>
                <div className="bg-box">
                    <img src={bg1010} alt="" />
                </div>
                <div className="invite">
                    <div className="invite-code">{this.state.inviter_code}</div>
                    <img src={lq} alt="" />
                </div>
                <div className="download-a" onClick={() => {
                    if (this.state.isiOS) { window.open(this.state.ios_ipa_url) } else { window.open(this.state.apk_url) }
                }}></div>
            </div>
        )
    }
}