/** @format */

import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import lq from "./image/lq.png";
import wdlq from "./image/wdlq.png";
import yjlq from "./image/yjlq.png";
/**
 * 双倍返利
 * @author zhanwc
 */
export default class DoubleRebate extends React.Component {
  constructor(props) {
    document.title = "双倍返利";
    super(props);
    this.state = {
      uname: "",
      showAlert: false,
      activity_time: "",
      get_rebate_time: "",
      status: 2,
      money: 0,
      rule: "",
    };
  }
  componentDidMount() {
    // 1:已领取 2：带岭区 0:没打成
    get("/activity/doubleRebateInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          uname: res.data.uname,
          activity_time: res.data.activity_time,
          get_rebate_time: res.data.get_rebate_time,
          status: res.data.status,
          money: res.data.money,
          rule: res.data.rule,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/doubleRebate", url: window.location.href },
      });
    }
  }

  async join(index) {
    let res = await post("/activity/getDoubleRebate", {});
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      this.setState({ status: 1 });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    let content = "";

    if (this.state.status === 0) {
      content = (
        <div className="text">
          当前最高单笔充值金额：{this.state.money}元
          <br />
          <br />
          <img onClick={this.join.bind(this)} src={lq} alt="" />
        </div>
      );
    } else if (this.state.status === 1) {
      content = (
        <div className="text">
          当前最高单笔充值金额：{this.state.money}元
          <br />
          <br />
          <img src={yjlq} alt="" />
        </div>
      );
    } else {
      content = (
        <div className="text">
          <img src={wdlq} alt="" />
        </div>
      );
    }

    return (
      <div className="AcDoubleRebate">
          <div className="time1">活动时间：{this.state.activity_time || "0000-00-00"}</div>
          <div className="time2">领取时间：{this.state.get_rebate_time || "0000-00-00"}</div>
          <div className="btn_box">{content}</div>
      </div>
    );
  }
}
