import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import table from "./image/table.png";
/**
 * 积分兑换活动
 * @author pangzh
 */
export default class CZYY extends React.Component {
  constructor(props) {
    document.title = "充值返还";
    super(props);
    this.state = {
      miliRebateList: [],
      uname: "",
      showAlert: false,
      appointment_date: "",
      balance_payment_date: "",
      current_date: "appointment_date",
      is_appointment: 0,
      reward: 0,
      rule: "",
    };
  }
  componentDidMount() {
    // 1:已领取 2：带岭区 0:没打成
    get("/activity/appointmentInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          miliRebateList: res.data.list,
          uname: res.data.uname,
          appointment_date: res.data.appointment_date,
          balance_payment_date: res.data.balance_payment_date,
          current_date: res.data.current_date,
          is_appointment: res.data.is_appointment,
          reward: res.data.reward,
          rule: res.data.rule,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/czyy", url: window.location.href },
      });
    }
  }
  async join(index) {
    let res = await post("/activity/makeAppointment", {});
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert() {
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      this.setState({
        showAlert: true,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    let content = "";
    if (this.state.current_date === "appointment_date") {
      if (this.state.is_appointment) {
        content = <div className="btn ylq">已参与</div>;
      } else {
        content = (
          <div className="btn wlq" onClick={this.join.bind(this)}>
            参与活动
          </div>
        );
      }
    } else {
      if (this.state.reward) {
        content = (
          <div className="text">
            您一共可获得米粒为：
            <br />
            {this.state.reward}
          </div>
        );
      } else {
        content = <div className="text">您未参与本次活动</div>;
      }
    }

    return (
      <div className="CZYY">
        <div className="time1">定金时间：{this.state.appointment_date}</div>
        <div className="time2">尾款时间：{this.state.balance_payment_date}</div>
        <div className="rebate_list">
          <img className="table" src={table} alt="" />
          <div
            className="rule"
            dangerouslySetInnerHTML={{
              __html: this.state.rule,
            }}
          ></div>
          <div className="btn_box">{content}</div>
        </div>
      </div>
    );
  }
}
