import React from "react";
import closeBtn from "../../assets/images/colse.png";
import { closeWebView } from "../../utils/appAction";
const back = () => {
  console.log("退出");
  closeWebView();
};

const style = {
  position: "fixed",
  right: "0.3rem",
  top: "0.3rem",
  width: "0.6rem",
  zIndex: "999",
  img: {
    width: "100%",
  },
};

const header = (props) => {
  return (
    <div>
      <div onClick={back} style={style}>
        <img src={closeBtn} style={style.img} alt="" />
      </div>
    </div>
  );
};

export default header;
