import React from "react";
import "./style.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import queryString from "query-string";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
// import ruleBg from "./image/rule.png";
import der from "./images/der.png";
import btn3 from "./images/btn3.png";
import PayChange from "./paychange";
/**
 * 积分兑换活动
 * @author pangzh
 */
export default class MiliGift extends React.Component {
  constructor(props) {
    document.title = "米粒礼包";
    super(props);
    this.state = {
      list: [],
      giftCode: [],
      prizes: [], // 实物列表
      isActive: true,
      balance: 0,
      activityTime: "",
      game: "",
      uname: "",
      rule: "",
      showAlert: false,
      shelter: "none",
    };
    this.getDirection = this.getDirection.bind(this);
  }
  componentDidMount() {
    this.getDirection();
    window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.getDirection, false);
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    get("/activity/miliGiftBagList", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          activityTime: res.data.activity_time,
          list: res.data.list,
          uname: res.data.uname,
          rule: res.data.rule,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  getDirection() {
    let w = window.document.documentElement.clientWidth;
    let h = window.document.documentElement.clientHeight;
    const ua = navigator.userAgent.toLowerCase();
    if (
      ua.indexOf("applewebkit") > -1 &&
      ua.indexOf("mobile") > -1 &&
      ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 &&
      ua.indexOf("android") === -1 &&
      ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 &&
      ua.indexOf("browser") === -1
    ) {
      if (window.orientation === 180 || window.orientation === 0) {
        this.setState({ shelter: "block" });
      }
      if (window.orientation === 90 || window.orientation === -90) {
        this.setState({ shelter: "none" });
      }
    } else {
      if (w > h) {
        this.setState({ shelter: "none" });
      } else {
        this.setState({ shelter: "block" });
      }
    }
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/sign",
        query: { backUrl: "/activity/miligift" },
      });
    }
  }

  /**
   * 切换列表
   * @param {*} flag
   */
  checkList(flag) {
    let isActive = flag;
    let goodList = [];
    if (flag) {
      goodList = this.state.giftCode;
    } else {
      goodList = this.state.prizes;
    }
    this.setState({
      isActive: isActive,
      goodList: goodList,
    });
  }

  receiveMili(bagId, index) {
    JmAlert.open({
      alertTip: "确定要领取米粒？",
      closeAlert: () => {
        console.log(bagId);
        post("/activity/getReward", {
          gift_bag_id: bagId,
        }).then((res) => {
          console.log(res);
          if (res.code === 0) {
            Toast.success("领取成功，请游戏内查看");
            const list = this.state.list;
            if (list[index].is_expire === 1) {
              list[index].status = 3;
            } else {
              list[index].status = 2;
            }
            this.setState({ list: list });
          } else if (res.code === 44309) {
            this.login();
          } else {
            Toast.fail(res.message);
          }
        });
      },
    });
  }

  pay(bagId) {
    console.log("打开");
    PayChange.open({
      pay: (payType) => {
        console.log(payType);
        post("/activity/miliGiftBagOrderCreate", {
          gift_bag_id: bagId,
          pay_type: payType,
          pay_platform: 14,
        }).then((res) => {
          console.log(res);
          if (res.code === 0) {
            window.location.href = res.data.payUrl;
          } else if (res.code === 44309) {
            this.login();
          } else {
            Toast.fail(res.message);
          }
        });
      },
    });
  }

  render() {
    return (
      <div className="mili_gift_bag">
        <div className="shelter" style={{ display: this.state.shelter }}>
          <div className="icon">
            <img src={der} alt="" />
            <div>为了更好的使用体验请横屏观看</div>
          </div>
        </div>
        <div className="content">
          <div className="title"></div>
          <div className="time">{this.state.activityTime}</div>
          <div className="table">
            <div className="table_head">
              <div className="name">礼包名称</div>
              <div className="paymili">购买的米粒</div>
              <div className="orther">额外的奖励</div>
              <div className="pay">购买</div>
              <div className="getmili">提取米粒</div>
            </div>
            <div className="table_body">
              {this.state.list.map((v, i) => {
                return (
                  <div key={v.gift_bag_id}>
                    <div className="name">{v.gift_bag_name}</div>
                    <div className="paymili">{v.mili}</div>
                    <div className="orther" dangerouslySetInnerHTML={{ __html: v.extra_info }}></div>
                    <div className="pay" onClick={this.pay.bind(this, v.gift_bag_id)}>
                      {v.money}元
                      <img src={btn3} alt="" />
                    </div>
                    <div className="getmili">
                      <Btn info={v} click={this.receiveMili.bind(this, v.gift_bag_id, i)}></Btn>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rule" dangerouslySetInnerHTML={{ __html: this.state.rule }}></div>
        </div>
      </div>
    );
  }
}

function Btn(props) {
  if (props.info.status === 0) {
    return <div>未购买</div>;
  } else if (props.info.status === 1) {
    if (props.info.is_expire === 1) {
      return (
        <div
          className="btn2"
          onClick={() => {
            props.click();
          }}
        ></div>
      );
    } else {
      return (
        <div
          className="btn1"
          onClick={() => {
            props.click();
          }}
        ></div>
      );
    }
  } else if (props.info.status === 2) {
    return <div>到期领取</div>;
  } else {
    return <div>提前领取</div>;
  }
}
