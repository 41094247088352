import React from "react";
import HttpUtils from "../../../utils/HttpUtils";
import queryString from "query-string";
import "./style.less";

export default class DyActivity extends React.Component {
  constructor(props) {
    document.title = "广告"; // 修改标题
    super(props);
    this.state = { date: new Date(), giftInfo: [], tag: "", type: 1, link: "" };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params) {
      console.log(params);
      console.log("有图片");
      this.setState({
        tag: params.tag
      });
    }

  }
  componentWillUnmount() {
    console.log("卸载页面");
  }
  render() {
    let content;
      content = (
        <div className="bg-box">
          <img className="bg"  src={this.state.tag} alt="" />
        </div>
      );

    return (
      <div className="win">
        {content}
      </div>
    );
  }
}
