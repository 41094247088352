import React from "react";
import "./style.less";
// import Header from "../../components/Header/index.jsx";
import JmAlert from "../../components/JmAlert/index.jsx";
import HttpUtils from "../../utils/HttpUtils";
import queryString from "query-string";
import userIcon from "./images/people.png";
import passIcon from "./images/password.png";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      uname: "",
      upass: "",
      phone: "",
      vcode: "",
      timeText: "获取验证码",
      redirect: "",
    };
    this.handleLoginTypeClick = this.handleLoginTypeClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.timeInter = this.timeInter.bind(this);
    // this.goBack = this.goBack.bind(this);
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params.backUrl) {
      this.setState({ redirect: params.backUrl });
    }
    console.log(params);
    console.log(this.props);
    console.log(this.props.location);
    HttpUtils.setCookie2("yj_mh_game_access_token", "", -1);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleLoginTypeClick() {
    if (this.state.isLoggedIn) {
      this.setState({ isLoggedIn: false });
    } else {
      this.setState({ isLoggedIn: true });
    }
  }
  toAlert(text) {
    // Alert.open({
    //   alertTip: text,
    //   closeAlert: function () {
    //     console.log("关闭了...");
    //   },
    // });
    JmAlert.open({
      title: "提示",
      alertTip: text,
    });
  }
  timeInter() {
    let num = 60;
    let inter = setInterval(() => {
      // this.
      num--;
      this.setState({ timeText: num });
      if (num <= 0) {
        clearInterval(inter);
        this.setState({ timeText: "获取验证码" });
      }
    }, 1000);
  }
  getVCode() {
    if (this.state.timeText !== "获取验证码") {
      return;
    }
    this.timeInter();
    if (this.state.phone === 0) {
      this.toAlert("手机号不能为空");
    } else if (!/^(1[3456789]\d{9}$)/.test(this.state.phone)) {
      this.toAlert("请输入正确的手机号码");
    } else {
      // this.sendYzm(); //启动ajax发送获取验证码函数
      HttpUtils.get("/webuser/sendsms?mobile=" + this.state.phone + "&sms_type=1", {
        mobile: this.state.phone,
        sms_type: 1,
      }).then((res) => {
        if (res.code === 0) {
          this.timeInter(); //获取验证码成功时启动定时器
        } else {
          this.toAlert(res.message);
        }
      });
    }
  }
  goLogin() {
    if (this.state.isLoggedIn) {
      if (this.state.phone === "") {
        this.toAlert("手机号不能为空");
      } else if (!/^(1[3456789]\d{9}$)/.test(this.state.phone)) {
        this.toAlert("请输入正确的手机号码");
      } else if (this.state.vcode === "" && this.state.vcode < 1) {
        this.toAlert("验证码不能为空");
      } else {
        HttpUtils.axios_post("/webuser/phoneLogin", {
          mobile: this.state.phone,
          code: this.state.vcode,
          code_area: "86",
        })
          .then((result) => {
            console.log(result);
            if (result.code === 0) {
              //登录成功，跳转至游戏进入页面
              let backUrl = sessionStorage.getItem("backUrl");
              console.log(backUrl);
              if (backUrl) {
                this.props.history.push(atob(backUrl));
                return;
              }
              //登录成功，跳回原来的页面
              // this.props.history.goBack();
              this.props.history.go(-1);
            } else {
              this.toAlert(result.message);
            }
          })
          .catch((error) => {});
      }
    } else {
      // 账号登录
      if (this.state.uname === "") {
        this.toAlert("帐号不能为空");
      } else if (this.state.upass === "") {
        //正则表达式判断
        this.toAlert("密码不能为空");
      } else if (!/([a-zA-Z0-9]|[._]){6,22}/.test(this.state.uname)) {
        this.toAlert("帐号请输入帐号6-22位数字+字母组合");
      } else if (!/^.{6,22}$/.test(this.state.upass)) {
        this.toAlert("请输入密码6-22位");
      } else {
        HttpUtils.axios_post("/webuser/accountLogin", {
          uname: this.state.uname,
          upass: this.state.upass,
        })
          .then((result) => {
            console.log(result);
            if (result.code === 0) {
              //登录成功，跳转至游戏进入页面
              //登录成功，跳回原来的页面
              // this.props.history.goBack();
              // this.props.history.go(-1);
              this.goBack();
            } else {
              this.toAlert(result.message);
            }
          })
          .catch((error) => {});
      }
    }
  }

  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = this.props.history.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }

  goBack() {
    let backUrl = sessionStorage.getItem("backUrl");
    console.log(backUrl);
    if (this.state.redirect) {
      this.props.history.push(atob(this.state.redirect));
      return;
    }
    if (backUrl) {
      this.props.history.push(atob(backUrl));
      return;
    }
    if (this.props.location.query && this.props.location.query.url) {
      window.location.href = this.props.location.query.url;
      return;
    }
    console.log(this.props.history);
    this.props.history.goBack();
  }

  render() {
    let InputBox;
    // let zButton;
    const isLoggedIn = this.state.isLoggedIn;
    if (isLoggedIn) {
      InputBox = (
        <div className="input_box">
          <div>
            <span>+86</span>
            <input
              name="phone"
              type="text"
              value={this.state.phone}
              onChange={this.handleInputChange}
              placeholder="请输入手机号码"
            />
          </div>
          <div>
            <span style={{ fontSize: "12px" }}>验证码</span>
            <input
              name="vcode"
              type="text"
              value={this.state.vcode}
              onChange={this.handleInputChange}
              placeholder="请输入验证码"
            />
            <div className="getVCode" onClick={this.getVCode.bind(this)}>
              {this.state.timeText}
            </div>
          </div>
        </div>
      );
      // zButton = <div onClick={this.handleLoginTypeClick}>账号登录</div>;
    } else {
      InputBox = (
        <div className="input_box">
          <div>
            <span>
              <img src={userIcon} alt="" />
            </span>
            <input
              name="uname"
              value={this.state.uname}
              onChange={this.handleInputChange}
              type="text"
              placeholder="请输入账号"
            />
          </div>
          <div>
            <span>
              <img src={passIcon} alt="" />
            </span>
            <input
              name="upass"
              value={this.state.upass}
              onChange={this.handleInputChange}
              type="password"
              placeholder="请输入密码"
            />
          </div>
        </div>
      );
      // zButton = <div onClick={this.handleLoginTypeClick}>手机登录</div>;
    }
    return (
      <div className="sign">
        {/* <Header title="登录" /> */}
        <div className="title">欢迎登录乐盟游戏</div>
        <div className="change_login">
          <ul>
            <li className={isLoggedIn ? "" : "active"} onClick={this.handleLoginTypeClick}>
              账号登录
            </li>
            <li className={isLoggedIn ? "active" : ""} onClick={this.handleLoginTypeClick}>
              手机登录
            </li>
          </ul>
        </div>
        {InputBox}
        <div className="btn_box">
          <div onClick={this.goLogin.bind(this)}>登录</div>
          {/* {zButton} */}
        </div>
        <div className="footer"></div>
      </div>
    );
  }
}
