export const closeWebView = () => {
  console.log("1232");
  /* eslint-disable */
  if (typeof window.webkit != "undefined") {
    window.prompt("closeWebView", "");
  } else if (typeof jimiJS !== "undefined" && jimiJS.closeWebView) {
    jimiJS.closeWebView();
  } else if (
    typeof parent.jimiJS !== "undefined" &&
    parent.jimiJS.closeWebView
  ) {
    parent.jimiJS.closeWebView();
  }
  /* eslint-disable */
};

/**
 * 加在URL在浏览器
 * @param {*} url
 */
export const loadUrlByBrowser = (url) => {
  /* eslint-disable */
  if (isWebkit) {
    window.prompt("loadUrlByBrowser", url);
  } else if (isJimiJs && jimiJS.loadUrlByBrowser) {
    // jimiJS.loadUrlByBrowser(url);
    parent.jimiJS.closeWebView(url);
  }
  /* eslint-disable */
};

export const isWebkit = () => {
  return typeof window.webkit != "undefined";
};

export const isJimiJs = () => {
  return typeof jimiJS !== "undefined";
};

const isParent = () => {
  return typeof parent.jimiJS !== "undefined";
};
