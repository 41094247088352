import React from "react";
import "./index.less";
import JmAlert from "../../../components/JmAlert/index.jsx";
import lq from "./images/lq.png";
import ylq from "./images/ylq.png";
import wdb from "./images/wdb.png";
import ok from "./images/ok.png";
import cancel from "./images/cancel.png";
import { get, post } from "../../../utils/axHttp";
import Toast from "../../../components/Toast/index.jsx";
import { getUrlActivityBg } from "../../../utils/tools";
export default class MiliWeeksCard extends React.Component {
  constructor(props) {
    super(props);
    document.title = "米粒周卡";
    this.state = {
      weekCardList: [],
      rule: "",
      btn: { 1: lq, 2: ylq, 0: wdb },
      index: -1,
      showAlert: false,
    };
  }

  componentDidMount() {
    // 1:已领取 2：带岭区 0:没打成
    getUrlActivityBg("week_card", "activity_miliWeeksCard");

    get("/activity/getWeeksCardList", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          weekCardList: res.data.week_card_list,
          rule: res.data.rule,
          uname: res.data.uname,
        });
      } else if (res.code === 44309) {
        this.login();
      } else {
        Toast.fail(res.message);
      }
    });
  }

  openAlert(status, index) {
    if (status === 1) {
      this.setState({ index: index, showAlert: true });
      console.log(status);
    }
  }

  closeAlert() {
    this.setState({ index: -1, showAlert: false });
  }

  async getWeeksCard() {
    let res = await post("/activity/getWeeksCard", {
      week_card_index: this.state.index,
    });
    console.log(res);
    if (res.code === 0) {
      let weekCardList = this.state.weekCardList;
      // weekCardList[this.state.index].status = 2;
      for (let i = 0; i <= this.state.index; i++) {
        weekCardList[i].status = 2;
      }
      this.setState({ weekCardList: weekCardList, showAlert: false });
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
    } else {
      this.setState({ showAlert: false });
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: {
          backUrl: "/activity/miliweekscard",
          url: window.location.href,
        },
      });
    }
  }

  render() {
    return (
      <div className="activity_miliWeeksCard">
        <div className="btn_list">
          {this.state.weekCardList.map((v, i) => {
            return (
              <div onClick={this.openAlert.bind(this, v.status, i)} key={i}>
                <img src={this.state.btn[v.status]} alt="" />
              </div>
            );
          })}
        </div>
        <div
          className="rule"
          dangerouslySetInnerHTML={{ __html: this.state.rule }}
        ></div>
        <div
          className="alert"
          style={{ display: this.state.showAlert ? "block" : "none" }}
        >
          <div>
            <div>是否确认选择本档次周卡？</div>
            <div>
              <img src={ok} onClick={this.getWeeksCard.bind(this)} alt="" />
              <img src={cancel} onClick={this.closeAlert.bind(this)} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
