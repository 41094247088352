import React, { useRef, useEffect, useState } from "react";

function VideoPopup(props) {
  console.log(props);
  const viRef = useRef(null);
  const [showPlay, setShowPlay] = useState(true);
  const [firstPlayer, setFirstPlayer] = useState(true);

  useEffect(() => {
    // console.log(viRef);
    viRef.current.onended = () => {
      console.log("播放完毕");
      setShowPlay(true);
      props.onStatus(3);
    };

    viRef.current.onpause = () => {
      console.log("视频暂停");
      setShowPlay(true);
    };
  });
  const onClose = () => {
    props.onClose();
  };

  const playVideo = () => {
    viRef.current.play();
    if (firstPlayer) {
      props.onStatus(2);
      setFirstPlayer(false);
    }
    setShowPlay(false);
  };

  return (
    <div className="video_popup">
      <div>
        <div className="vi_box">
          <video
            preload="true"
            controls={false}
            webkit-playsinline="true"
            x-webkit-airplay="true"
            src={props.tag}
            ref={viRef}
            poster={props.cover}
          ></video>
          <div className="border_box">
            <div className="play" onClick={playVideo} style={{ display: showPlay ? "block" : "none" }}></div>
          </div>
        </div>
        <div className="btn"></div>
        <div className="close" onClick={onClose}></div>
      </div>
    </div>
  );
}

export { VideoPopup };
