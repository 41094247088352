import React from "react";
import "./style.less";
import closeIcon from "../../../assets/images/close.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const copy = () => {
  console.log("Copy");
};

const ZQ = (props) => {
  if (!props.warn) {
    return null;
  }
  return (
    <div className="ActQuestionnaireZq">
      <div className="content">
        <img
          src={closeIcon}
          onClick={() => {
            props.close();
          }}
          alt=""
        />
        <div>问卷礼包</div>
        <div>
          礼包激活码：<span className="gift">{props.code}</span>
        </div>
        <CopyToClipboard
          text={props.code} //点击复制时的内容,可自行设置或传入
          onCopy={copy} //点击之后的回调
        >
          <div className="btn">
            <span>复制礼包激活码</span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ZQ;
