import React from "react";
import "./style.less";
import Toast from "../../../components/Toast/index.jsx";

export default class SmashEgg extends React.Component {
  constructor(props) {
    // document.title = "签到"; // 修改标题
    super(props);
    this.state = {};
    // this.onSignGift = this.onSignGift.bind(this);
  }
  onToast() {
    Toast.info("123213");
  }
  render() {
    return (
      <div className="SmashEgg">
        <div>
          <button onClick={this.onToast.bind(this)}>1231</button>
        </div>
      </div>
    );
  }
}
