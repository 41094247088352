import React from "react";

const Header = (Props) => {

    

    const style = {
    textAlign: "center",
    padding: "10px",
    color: "#fff",
    backgroundColor: "#03A9F4",
  };

  return (
    <div style={style}>
      <span>{Props.name}</span>
    </div>
  );
};

export default Header;
