import React from "react";
import "./style.less";
import { get, post } from "../../utils/axHttp";
import JmAlert from "../../components/JmAlert/index.jsx";

export default class Wait extends React.Component {
  constructor(props) {
    document.title = "开服预约";
    super(props);
    this.state = {
      mobile: "",
      vcode: "",
      vCodeText: "获取验证码",
      isSendSms: true,
      moreCss: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    get("/activity/webAppointmentInfo", {}).then((res) => {
      console.log(res);
      if (res.code === 0) {
        let moreCss = {};
        res.data.left && (moreCss.paddingLeft = res.data.left + "rem");
        res.data.color && (moreCss.color = res.data.color);
        this.setState({
          backgroundImageBg: `url(${res.data.background_img})`,
          backgroundImageAlert: `url(${res.data.alert_img})`,
          backgroundImageBtn: res.data.button_img,
          rule: res.data.rule,
          mobile: res.data.mobile,
          isBindMobile: res.data.is_bind_mobile,
          isLog: res.data.is_log,
          moreCss: moreCss,
        });
      } else if (res.code === 44309) {
        this.props.history.push({
          pathname: "/login",
          query: { backUrl: "/activity/lottery", url: window.location.href },
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  getVcode() {
    if (!this.state.isSendSms) {
      return;
    } else {
      this.setState({ isSendSms: false });
    }
    get("/webconfig/sendSms", {
      phone: this.state.mobile,
      check: 1,
    }).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.vCodeBtn();
        JmAlert.open({
          title: "恭喜",
          alertTip: res.message,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  vCodeBtn() {
    let num = 60;
    let timer = setInterval(() => {
      if (num <= 0) {
        this.setState({ vCodeText: "获取验证码", isSendSms: true });
        window.clearInterval(timer);
        return;
      }
      this.setState({ vCodeText: num });
      num--;
    }, 1000);
  }

  /**
   * 绑定并且预约
   */
  bindPhone() {
    if (this.state.isBindMobile) {
      this.order();
      return;
    }
    post("/webconfig/bindPhone", {}).then((res) => {
      if (res.code === 0) {
        this.setState({ isBindMobile: 1 });
        this.order();
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  order() {
    post("/Activity/appointmentLog", {}).then((res) => {
      if (res.code === 0) {
        this.setState({ isLog: 1 });
        JmAlert.open({
          title: "恭喜",
          alertTip: res.message,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    let btnText;
    if (this.state.isLog) {
      btnText = (
        <div
          style={{ marginTop: 20, fontSize: 14, fontWeight: 700, color: "red" }}
        >
          手机号：{this.state.mobile}，已预约
        </div>
      );
    } else {
      btnText = (
        <div className="btn">
          <img
            onClick={this.bindPhone.bind(this)}
            src={this.state.backgroundImageBtn}
            alt=""
          />
        </div>
      );
    }

    return (
      <div
        className="wait_box"
        style={{ backgroundImage: this.state.backgroundImageBg }}
      >
        <div
          className="rule"
          style={{
            backgroundImage: this.state.backgroundImageAlert,
            ...this.state.moreCss,
          }}
          dangerouslySetInnerHTML={{ __html: this.state.rule }}
        ></div>
        <div className="scanner">
          <div
            style={{
              display: this.state.isLog ? "none" : "flex",
            }}
          >
            <div>手机号码</div>
            <input
              type="text"
              placeholder="请输入手机号码"
              value={this.state.mobile}
              onChange={this.handleInputChange}
              readOnly={this.state.isBindMobile}
              name="mobile"
            />
          </div>
          <div
            style={{
              display: this.state.isBindMobile ? "none" : "flex",
            }}
          >
            <div>验证码</div>
            <input
              type="text"
              placeholder="请输入验证码"
              value={this.state.vcode}
              onChange={this.handleInputChange}
              name="vcode"
            />
            <div className="get_v_code" onClick={this.getVcode.bind(this)}>
              {this.state.vCodeText}
            </div>
          </div>
        </div>
        {btnText}
      </div>
    );
  }
}
