import React, { Fragment } from "react";
import manImg from "../image/letter/man.png";
import womenImg from "../image/letter/women.png";
export default class LetterPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.vi = React.createRef();
    this.viBox = React.createRef();
    this.playVideo = this.playVideo.bind(this);
    this.show = this.show.bind(this);
    this.cover = props.cover;
    console.log(props);
  }

  playVideo() {
    console.log("播放视频");
    console.log(this.vi);
    this.viBox.current.style.position = "fixed";
    this.viBox.current.style.top = 0;
    this.viBox.current.style.left = 0;
    this.viBox.current.style.right = 0;
    this.viBox.current.style.bottom = 0;
    this.viBox.current.style.zIndex = 1;
    this.vi.current.play();
    this.props.onStatus(2); // 上报状态点击了播放
    this.vi.current.onended = () => {
      console.log(this.props.isJump);
      this.props.onStatus(3); // 上报状态 播放完毕
      if (this.props.isJump) {
        document.querySelector(".toUrl").style.display = "block";
      }
    };
  }

  show() {
    console.log(document.querySelector(".letterFm"));
    document.querySelector(".letterFm").classList.add("letterHidden");
    document.querySelector(".letter").classList.add("letterShow");
  }

  render() {
    let figureImg;
    if (this.props.sex === "women") {
      figureImg = <img className="women" src={womenImg} alt="" />;
    } else {
      figureImg = <img className="man" src={manImg} alt="" />;
    }
    return (
      <Fragment>
        <div className="letterFm" onClick={this.show}></div>
        <div className="letter">
          <div>
            <div className="vi_box" ref={this.viBox}>
              <video
                poster={this.cover}
                webkit-playsinline=""
                playsinline="true"
                src={this.props.tag}
                ref={this.vi}
              ></video>
            </div>
            {figureImg}
          </div>
          <div>
            <div onClick={this.playVideo}>
              <img src={require("../image/letter/open.png")} alt="" />
            </div>
            <div onClick={() => this.props.close()}>
              <img src={require("../image/letter/close.png")} alt="" />
            </div>
          </div>
          {/* <div className="toUrl" onClick={() => this.props.link()}>
            点击查看详情
          </div> */}
          <img className="toUrl" onClick={() => this.props.link()} src={require("../image/btn.png")} alt="" />
        </div>
      </Fragment>
    );
  }
}
