import React, { Fragment } from "react";
import "./style.less";
import quan from "./image/quan.png";
import mili from "./image/mili.png";
import IeAlert from "./alert/index.jsx";
import JmAlert from "../../../components/JmAlert/index.jsx";
import queryString from "query-string";
import { get, post } from "../../../utils/axHttp";
import { getUrlActivityBg } from "../../../utils/tools";

/**
 * 积分兑换活动
 * @author pangzh
 */
export default class IntegralExchange extends React.Component {
  constructor(props) {
    document.title = "积分兑换";
    super(props);
    this.state = {
      dhList: [],
      showAlert: false,
      rule: "",
      showAlertType: "",
      alertText: "",
      userIntegral: 0, // 积分
      game_id: "",
      activityTime: "",
    };
    // this.onAlert = this.onAlert.bind(this);
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log("积分兑换", params);
    getUrlActivityBg("integral_shop", "ie_box");

    get("/activity/integralShopList", {
      access_token: params.access_token,
      game_id: params.game_id,
    }).then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          dhList: res.data.list,
          rule: res.data.rule,
          userIntegral: res.data.user_integral,
          uname: res.data.uname,
          game_id: params.game_id,
          activityTime: res.data.activity_time,
        });
      } else {
        JmAlert.open({
          title: "提示",
          alertTip: res.message,
        });
      }
    });
  }

  login() {
    if (!this.state.uname) {
      this.props.history.push({
        pathname: "/login",
        query: { backUrl: "/activity/lottery" },
      });
    }
  }
  async exChange(id, index) {
    let res = await post("/activity/integralExchange", {
      goods_id: id,
      game_id: this.state.game_id,
    });
    if (res.code === 0) {
      JmAlert.open({
        title: "恭喜",
        alertTip: res.message,
      });
      let dhList = this.state.dhList;
      dhList[index].num--;
      this.setState({
        userIntegral: this.state.userIntegral - dhList[index].integral_cost,
        ...dhList,
      });
    } else {
      JmAlert.open({
        title: "提示",
        alertTip: res.message,
      });
    }
    console.log(res);
  }
  async onAlert(showAlertType) {
    console.log(showAlertType);
    if (this.state.showAlert) {
      this.setState({ showAlert: false });
    } else {
      if (showAlertType === "record") {
        let res = await get("/activity/integralExchangeRecord", {
          game_id: this.state.game_id,
        });
        console.log(res);
        if (res.code === 0) {
          this.setState({
            showAlert: true,
            showAlertType: showAlertType,
            alertText: res.data.list,
          });
        } else {
          JmAlert.open({
            title: "提示",
            alertTip: res.message,
          });
        }
        return;
      }
      this.setState({
        showAlert: true,
        showAlertType: showAlertType,
        alertText: this.state.rule,
      });
    }
  }
  render() {
    return (
      <div className="ie_box">
        <div className="top_box">
          <div>活动时间：{this.state.activityTime}</div>
          <div className="uname" onClick={this.login.bind(this)}>
            {this.state.uname || "请登录"}
          </div>
        </div>
        <div className="btn_box">
          <div onClick={this.onAlert.bind(this, "rule")}>活动规则</div>
          <div onClick={this.onAlert.bind(this, "record")}>兑换记录</div>
        </div>
        <div>当前积分：{this.state.userIntegral}</div>
        <ul className="dh_list">
          {this.state.dhList.map((v, i) => {
            let names = "";
            if (v.good_type === "vouchers") {
              names = (
                <div>
                  <img src={quan} alt="" />
                  <div>{v.vouchers_name}</div>
                </div>
              );
            } else if (v.good_type === "mili") {
              names = (
                <div>
                  <img src={mili} alt="" />
                  <div>{v.mili_name}</div>
                </div>
              );
            } else {
              names = (
                <Fragment>
                  <div>
                    <img src={quan} alt="" />
                    <div>{v.vouchers_name}</div>
                  </div>
                  <div>
                    <img src={mili} alt="" />
                    <div>{v.mili_name}</div>
                  </div>
                </Fragment>
              );
            }

            return (
              <li key={i}>
                <div className="jf">
                  <div>{v.integral_cost}积分</div>
                  <div>次数：{v.num}</div>
                </div>
                <div className="imgs">{names}</div>
                <div>
                  <div
                    className="dh_btn"
                    onClick={this.exChange.bind(this, v.id, i)}
                  >
                    兑换
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <IeAlert
          showAlertType={this.state.showAlertType}
          showAlert={this.state.showAlert}
          text={this.state.alertText}
          close={this.onAlert.bind(this)}
        />
      </div>
    );
  }
}
