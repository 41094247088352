import one from "./image/2.png";
import colse from "./image/colse.png";
import React from "react";
import "./style.less";
import { CopyToClipboard } from "react-copy-to-clipboard";
export default class Window extends React.Component {
  constructor(props) {
    document.title = "广告"; // 修改标题
    super(props);
    this.state = { date: new Date(), giftInfo: [] };
  }
  closeWebView() {
    console.log("1232");
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (
      typeof parent.jimiJS !== "undefined" &&
      parent.jimiJS.closeWebView
    ) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <img className="colse" src={colse} onClick={this.closeWebView} alt="" />
        <CopyToClipboard
          text={"乐盟游戏"} //点击复制时的内容,可自行设置或传入
        >
          <img className="bg" src={one} alt="" />
        </CopyToClipboard>
      </div>
    );
  }
}
