import React from "react";
import "./style.less";
import {post } from "../../utils/axHttp";
import JmAlert from "../../components/JmAlert/index.jsx";
import btn from "./image/kfbtn.png";
import colse from "./image/kfclose.png";

export default class Wait extends React.Component {
  constructor(props) {
    document.title = "客服弹窗";
    super(props);
    this.state = {
      mobile: "",
      vcode: "",
      isSendSms: true,
      moreCss: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.getUserInfo();
    this.popupStatistical(998);
  }

  popupStatistical(isJump) {
    post("/Popup/PopupStatistical", {
      popup_id: 999,
      popup_type: 1,
      popup_online_time: 0,
      is_jump: isJump,
    })
      .then((result) => {
        console.log(result);
        window.clearInterval(this.state.onInterval);
        this.state.isPopupStatistical = true;
      })
      .catch((error) => {});
  }

  getUserInfo() {
    post("/webuserinfo/getUserInfo",{})
      .then((result) => {
        console.log(result);

        if(result.data.kf_qrcode){
          this.setState({kf_qr_code:result.data.kf_qrcode});
          console.log(this.state);
        }
      })
      .catch((error) => {});
  }

  order() {
    if (!/^1[3-9]\d{9}$/.test(this.state.mobile)) {
      JmAlert.open({
        title: "提示",
        alertTip: "手机号码输入有误",
      });
      return;
    }

    post("/Activity/setUserMergePhone", { phone: this.state.mobile }).then(
      (res) => {
        if (res.code === 0) {
          JmAlert.open({
            title: "提示",
            alertTip: "登记成功",
          });
        } else {
          JmAlert.open({
            title: "提示",
            alertTip: res.message,
          });
        }
      }
    );
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  closeWebView() {
    console.log("1232");
    /* eslint-disable */
    if (typeof window.webkit != "undefined") {
      window.prompt("closeWebView", "");
    } else if (typeof jimiJS !== "undefined" && jimiJS?.closeWebView) {
      jimiJS.closeWebView();
    } else if (
      typeof parent.jimiJS !== "undefined" &&
      parent.jimiJS.closeWebView
    ) {
      parent.jimiJS.closeWebView();
    }
    /* eslint-disable */
  }

  render() {
    let btnText;
    btnText = (
      <div className="btn">
        <img onClick={this.order.bind(this)} src={btn} alt="" />
      </div>
    );

    return (
      <div className="wait_box_index3">
        <img className="colse" src={colse} onClick={this.closeWebView} alt="" />
        <div
          className="rule"
          style={{
            backgroundImage: `url(${this.state.kf_qr_code})`
          }}
        >
        </div>
        <div className="scanner">
          <div >
            <input
              type="text"
              placeholder="填写手机号码可优先添加VIP客服"
              value={this.state.mobile}
              onChange={this.handleInputChange}
              readOnly={this.state.isBindMobile}
              name="mobile"
            />
          </div>
        </div>
        {btnText}
      </div>
    );
  }
}
